import React from 'react'
import { MaterialTable } from '../generic'
import { printCurrency, printDate } from '../../lib/helpers'

const cols = [
  {
    Header: 'Period',
    accessor: 'period',
  },
  {
    Header: 'Contribution Amount',
    accessor: 'contributions',
    Cell: ({ value }) => printCurrency((value || []).reduce((total, contribution) => total + contribution.amount, 0))
  },
  {
    Header: 'Payments Total',
    accessor: 'payments',
    Cell: ({ value }) => printCurrency((value || []).reduce((total, payment) => total + payment.amount, 0))
  },
  {
    Header: 'Fully Paid',
    accessor: 'isPaid',
    Cell: ({ value }) => value ? 'Yes' : 'No'
  },
  {
    Header: 'Date Paid',
    accessor: 'unitDate',
    Cell: ({ value }) => printDate(value)
  },
]

const ContributionsHistory = ({ contributions, balance }) => {
  const data = contributions || []

  return (
    <>
      <div style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
        Contributions History | Balance: {printCurrency(balance)}
      </div>
      {data && <MaterialTable columns={cols} data={data} />}
    </>
  )
}

export default ContributionsHistory
