import React, { useState, Fragment } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ClientInformation, ClientDocuments, ClientNotes, PaymentStatus } from '../shared'
import ProductCommunication from '../communication/ProductCommunication'
import { ActivityIndicator, Button, Card, FormModal, Select, Modal, Grid, DisplayItem, TextField } from '../generic'
// import GraceDays from '../generic/GraceDays'
import PolicyStatusHistory from '../credit-life/PolicyStatusHistory'
import PaymentHistory from '../credit-life/PaymentHistory'
import fileDownload from 'js-file-download'
import EditCC from './EditCC'
import Obligations from './Obligations'
import OutstandingRequirements from './OutstandingRequirements'
import { API_URL } from '../../config';
import { differenceInDays } from 'date-fns'
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import Terms from '../funeral/Terms';
import Cancel from '../shared/Cancel';
import Client from '../clients/Client';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import BankAccount from '../bankAccount/BankAccount';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    marginLeft: 3,
    marginTop: 7,
    // position: 'absolute',
    // top: 9, left: '8em'
    // color: '#fff',
    fontSize: 21,
    fontWeight: "bold"
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingTop: 7,
    paddingBottom: 7
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
}));

// switch between locales
Numeral.locale('en-za')

export const friendlyType = (type) => type ? type.replace(/_/g, "").replace(/AND/g, "+").replace(/WITH/g, "+") : ''

export const translateState = (value) => {
  switch (value) {
    case 'INFO_MISSING' || 'MISING_INFO': return 'INCOMPLETE APPLICATION'
      break
    case 'DORMANT': return "POLICY ISSUED"
      break
    case 'NOT_TAKEN_UP': return 'NOT TAKEN UP'
      break
    case 'LAPSED': return 'LAPSED'
      break
    case 'ACTIVE': return 'ON RISK'
      break
    case 'GRACE': return "ON RISK GRACE"
      break
    case 'ON_RISK_GRACE': return "ON RISK GRACE"
      break
    case 'CLAIMED': return 'CLAIMED'
      break
    case 'CANCELLED': return 'CANCELLED '
      break
    default: return 'UNKNOWN'
      break
  }
}

const CancelPolicy = ({ setOpen }) => (
  <Button color='secondary' onClick={() => setOpen(true)}>Cancel Policy</Button>
)

export const EditModal = (props) => {
  return (
    <FormModal
      componentProps={props}
    />
  )
}

const CREATE_NOTE = gql`
	mutation($input: CreateNoteInput! ) {
		createNote(input: $input) {
      id
      text
      filesIds
      associatedWith {
        type
        id
      }
      createdAt
      createdBy {
          description
        }
      updatedAt
      updatedBy {
        email
        description
      }
		}
	}
`;

export const ccInformation = gql`
  query ClearanceCertificate($id: String!) {
    clearancecertificate(id: $id){
      id
      clientId
      client {
          id
          firstNames
          surname
          idNumber
        }
      paymentDay
      paymentMethod
      numInstallments
      paymentBankAccountId
      paymentBankAccount {
        id
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderContactNumber
        accountHolderIdNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
        }
      debitOrderId
      signatureId
      secondarySignatureId
      outstandingRequirements
      forObligations {
        id
        obligationId
        status
        statusAt
        statusReason
        obligationId
        obligation {
          id
          originalBalance
          currentBalance
          clientCreditor {
            description
          }
          reference
          settlementLetters {
            id
            verified
            fileId
            file {
              id
              url
              contentType
              }
            }
          }
        }
      # clearanceCertificate {
      #   id
      #   name
      #   file {
      #     id
      #     url
      #   }
      #   fromSimplicity
      #   verified
      # }
      state
      signature {
        id
        url
      }
      applicationDate
      previousDebtCounsellor {
        id
        fullName
      }
      certificateNumber
      secondaryApplicantId
      amount
      amountPaid
      dcTransferStartedAt
      dcTransferCompletedAt
      dcTransferRejectedAt
      settlementLettersRequested
      signupSalesInteraction {
        id
        salesPerson {
          id
          first
          last
          email
        }
      }
      createdAt
      state
      payments {
        id
        paymentFor
        timestamp
        amount
        paymentType
      }
      stateChanges {
        statusAfter
        updatedAt
      }
    }
  }
`;

const SEND_OZOW_PAYMENT_LINK = gql`
query ($associatedProducts: [ProductAmountInput!]!) {
  sendOzowSmsUrl(associatedProducts: $associatedProducts)
  }
`;



const UPDATE_CC = gql`
	mutation($input: UpdateClearanceCertificateInput!, $id: String!) {
    updateClearanceCertificate(id: $id, input: $input) {
      id
      secondaryApplicantId
		}
	}
`;

const DC_TRANSFER_COMPLETED = gql`
	mutation($id: String!) {
		dcTransferCompleted(id: $id) {
      id
      state
		}
	}
`;

const DC_TRANSFER_REJECTED = gql`
	mutation($id: String!) {
		dcTransferRejected(id: $id) {
      id
      state
		}
	}
`;


const CREATE_DC_LEAD = gql`
	mutation($id: String!) {
		createAsDCLead(id: $id) {
      id
      state
		}
	}
`;

const SEND_SIGNATURE_LINK = gql`
	mutation($clearanceCertificateId: String!, $clientId: String) {
		ClearanceCertificateSignatureLink(clearanceCertificateId: $clearanceCertificateId, clientId: $clientId ) {
      id
		}
	}
`;

const PROCESS_APPLICATION = gql`
	mutation($id: String!) {
		processClearanceCertificateApplication(id: $id) {
      id
      state
		}
	}
`;

const INSTALLMENTS_FREQUENCY = [
  { label: '3 Months', value: 3 },
  { label: 'Once-Off', value: 0 }
]

//proofDocumentIds
const UPDATE_OCC = gql`
	mutation($input: ObligationClearanceCertificateInput!, $clearanceCertificateId: String!) {
		addOrUpdateObligationClearanceCertificate(clearanceCertificateId: $clearanceCertificateId, input: $input) {
      id
      forObligations {
        id
        status
        statusAt
        statusReason
        obligation {
          id
          clientCreditor {
            description
          }
          reference
          settlementLetters {
            id
            fileId
            file {
              id
              url
              }
            }
          }
        }
		}
	}
`;

const Rejected = ({ id, client }) => {
  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState("")

  const rejectObligation = (status) => {
    client.mutate({
      mutation: DC_TRANSFER_REJECTED,
      variables: {
        id,
        reason
      }
    })
  }

  return (<>
    <Button onClick={() => setOpen(true)}>Transfer Rejected</Button>
    <Dialog fullWidth maxWidth="md"  open={open} size={"large" } onClose={() => setOpen(false)}>
      <DialogTitle>Update Certicate To Show That The DC Transfer Was Rejected</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Why was the transfer rejected? <br />
          <TextField fullWidth onChange={(e) => setReason(e.target.value)} value={reason}  />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={!reason } onClick={() => {
          rejectObligation()
          setOpen(false)
        }
        } color="primary" autoFocus>
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}

const CreateAsDCLead = ({ id, client }) => {
  const [open, setOpen] = useState(false)

  const createDcLead = () => {
    client.mutate({
      mutation: CREATE_DC_LEAD,
      variables: {
        id
      }
    })
  }

  return (<>
    <Button onClick={() => setOpen(true)}>DC Lead</Button>
    <Dialog fullWidth maxWidth="md"  open={open} size={"large" } onClose={() => setOpen(false)}>
      <DialogTitle>Are you sure you want to create a DC Lead and end CC process?</DialogTitle>
      <DialogActions>
        <Button onClick={() => {
          createDcLead()
          setOpen(false)
        }} color="primary" autoFocus>
          Create Lead
        </Button>
        <Button onClick={() => {
          setOpen(false)
        }} color="secondary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}

const requestDoc = async (id, template) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await fetch(`${API_URL}clearancecertificate/${id}/${template}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  return await response.blob()
}

let ClearanceCertificateInfo = ({ history, cancel, data, id, effectiveDate, ...props }) => {
  const classes = useStyles()
  const confirm = useConfirm();
  const [open, setOpen] = useState(false)
  const [showBank, setShowBank] = useState(false)
  const [reason, setReason] = useState()
  const [fileId, setFileId] = useState()
  const [text, setText] = useState()
  const [cancelUnCancel, setCancelUnCancel] = useState()
  const [disablePDF, setDisablePDF] = useState(false)
  const [spin, setSpin] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackText, setSnackText] = useState()

  const updateCc = (input) => {
    props.client
      .mutate({
        mutation: UPDATE_CC,
        variables: {
          id,
          input
        },
        refetchQueries: [{ query: ccInformation, variables: { id } }]
      })
  }

  const sendOzowPaymentLink = () => {
    props.client
      .query({
        query: SEND_OZOW_PAYMENT_LINK,
        variables: {
          associatedProducts: [{
            productId: id,
            productType: 'CLEARANCE_CERTIFICATE',
            amount: clearancecertificate.amount,
          }]
        }
      })
      .then((result) => {
        setSnackSeverity('success')
        setSnackOpen(true);
        setSnackText('Ozow SMS link sent');
        // console.log("SEND_OZOW_PAYMENT_LINK", result);
      });
  };
  

  const PayByInstallments = ({ id, client }) => {
    const [open, setOpen] = useState(false)
    const [installments, setInstallments] = useState(false)

    const addInstallments = (input) => {
      props.client.mutate({
        mutation: UPDATE_CC,
        variables: {
          id,
          input
        },
        refetchQueries: [{ query: ccInformation, variables: { id } }]
      })
    }

    return (<>
      <Button color="primary" onClick={() => setOpen(true)}>Setup Installments</Button>
      <Dialog fullWidth maxWidth="md" open={open} size={"large"} onClose={() => setOpen(false)}>
        <DialogTitle>Adding Installments may change the price, are you sure you want to proceed?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Select
              label='Select frequency'
              options={INSTALLMENTS_FREQUENCY}
              onChange={(e) => {
                setInstallments(e.target.value)
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            addInstallments({ numInstallments: installments })
            setOpen(false)
          }} color="primary" autoFocus>
            Apply Payment Term
          </Button>
          <Button onClick={() => {
            setOpen(false)
          }} color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>)
  }


  const setSecondaryApplicant = (secondaryApplicantId) => {
    props.client
      .mutate({
        mutation: UPDATE_CC,
        variables: {
          input: {
            secondaryApplicantId: secondaryApplicantId
          },
          id: data.clearancecertificate.id,
        }
      })
  }

  const processApplication = () => {
    client.mutate({
      mutation: PROCESS_APPLICATION,
      variables: {
        id
      }
    })
  }

  const updateOcc = (uuid, fileId) => {
    console.log('UpdateOcc', id, uuid, fileId)
    props.client
      .mutate({
        mutation: UPDATE_OCC,
        variables: {
          clearanceCertificateId: id,
          input: { id: uuid, proofDocumentIds: [fileId], status: 'PAID_UP_CLIENT_PROVIDE_PROOF' }
        },
        // refetchQueries: [{ query: ccInformation, variables: { id } }]
      })
  }

  const updateOccStatus = (uuid, status) => {
    console.log('UpdateOccStatus', id, uuid, fileId)
    props.client
      .mutate({
        mutation: UPDATE_OCC,
        variables: {
          clearanceCertificateId: id,
          input: { id: uuid, status }
        },
        refetchQueries: [{ query: ccInformation, variables: { id } }]
      })
  }


  const createNote = (input) => {
    props.client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
  }

  const dcTransferCompleted = (id) => {

    confirm({
      title: 'Complete DC transfer',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Has the client completed their transfer to Meerkat?`
    })
      .then(() => {
        props.client.mutate({
          mutation: DC_TRANSFER_COMPLETED,
          variables: {
            id,
          }
        })
      })
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const sendSignatureLink = (clearanceCertificateId, clientId) => {
      confirm({
        title: 'Send Signature Link via SMS',
        cancellationButtonProps: { variant: 'contained', color: 'default' },
        confirmationButtonProps: { variant: 'contained', color: 'primary' },
        description: `Press 'OK' to go ahead with sending the link, press 'CANCEL' stop the operation.`
      }).then(() => {
        props.client
          .mutate({
            mutation: SEND_SIGNATURE_LINK,
            variables: {
              clearanceCertificateId,
              clientId
            }
          }).then(() => {
            setSnackSeverity('success')
            setSnackOpen(true);
            setSnackText('Signature SMS Reminder sent');
          })
      })
  }

  const downloadDoc = async (template, id, certificateNumber) => {
    setDisablePDF(true)
    const blob = await requestDoc(id, template)
    fileDownload(blob, `${certificateNumber}_${template}.pdf`)
    setDisablePDF(false)
  }

  const { clearancecertificate, loading, error } = data || {};

  if (loading) {
    return <ActivityIndicator />;
  }

  if (error) {
    return <Card>An error occurred ...</Card>;
  }

  if (!clearancecertificate) {
    return <Card>Reload ...</Card>;
  }

  const { client, outstandingRequirements, forObligations, settlementLettersRequested, paymentMethod, state, amount, amountPaid, certificateNumber, createdAt, paymentBankAccount, easyPayNumber, dcTransferStartedAt, dcTransferCompletedAt, dcTransferRejectedAt, previousDebtCounsellor, signatureId, secondarySignatureId, numInstallments } = clearancecertificate || {};


  return (<>
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={3}>Certificate#: {certificateNumber}</Grid>
      <Grid item xs={4} >State: {state}</Grid>
    </Grid>
    <Card>


      <ClientInformation id={clearancecertificate.clientId} refetchQuery={ccInformation} cancel={cancel} history={history} productId={clearancecertificate.id} />
      <br />
      {clearancecertificate.secondaryApplicantId
        ? <ClientInformation title="Spouse" id={clearancecertificate.secondaryApplicantId} refetchQuery={ccInformation} cancel={cancel} history={history} clearancecertificate={clearancecertificate.id} productId={clearancecertificate.id} />
        : <EditModal apolloClient={props.client} Content={Client} setSecondaryApplicant={setSecondaryApplicant} displayItem={false} mode={'add'} icon={"add"} text={"Add Secondary Applicant"}  heading={"Add Secondary Applicant"} id={clearancecertificate.clientId} noButton={true} />
      }
      <br />
      <br />

      <div style={{ textTransform: 'capitalize', fontSize: 21, fontWeight: 400, textAlign: 'start' }}>
        Clearance Certificate Information
      </div>
      <Grid container className={classes.center} spacing={1}>
        {(state === 'DC_TRANSFER_IN_PROGRESS' || state === 'DC_TRANSFER_REJECTED' || state === 'DC_TRANSFER_FAILED') && <>
          <Grid item xs={2}><Button onClick={() => dcTransferCompleted(id)}>Transfer Complete</Button></Grid>
          <Grid item xs={2}><Rejected id={id} client={props.client} /></Grid>
          {/* <Grid item xs={2}><Button onClick={() => processApplication()}>Process Application</Button></Grid> */}
        </>
        }
        {(state === 'CLIENT_INFO_REQUIRED' || state === 'CLIENT_SETTLEMENT_LETTERS_REQUIRED' ) && <>
          <Grid item xs={2} >
            <CreateAsDCLead id={id} client={props.client} />
          </Grid>
        </>}
        {state !== 'CANCELLED' && <>
          <Grid item xs={2} >
            <Button color='secondary' onClick={() => {
              setCancelUnCancel("CANCEL");
              setOpen(true)
            }}>Cancel</Button>
          </Grid>
        </>}
        {state === 'CANCELLED' && <Grid item xs={2} >
          <Button color='secondary' onClick={() => {
            setCancelUnCancel("UNCANCEL");
            setOpen(true)
          }}>UnCancel</Button>
        </Grid>}
        <Grid item xs={2} >
          <PayByInstallments id={id} />
        </Grid>
      </Grid>
      {open && <Modal open={open}><Cancel productType={"CLEARANCE"} cancelUnCancel={cancelUnCancel} clientId={clearancecertificate.clientId} productId={clearancecertificate.id} setOpen={setOpen} /></Modal>}   

      <OutstandingRequirements forObligations={forObligations} amount={amount} amountPaid={amountPaid} outstandingRequirements={outstandingRequirements} />
      {!signatureId &&
        <Button color='secondary' onClick={() => sendSignatureLink(clearancecertificate.id, clearancecertificate.clientId)}> Send Signature Link </Button>
      } &nbsp; &nbsp;
      {clearancecertificate.secondaryApplicantId && !secondarySignatureId &&
        <Button color='secondary' onClick={() => sendSignatureLink(clearancecertificate.id, clearancecertificate.secondaryApplicantId)}> Send Secondary Applicant Signature Link </Button>
      }

      <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} >
          {snackText}
        </Alert>
      </Snackbar>

      <Obligations clearanceCertificateId={id} state={state} forObligations={forObligations} clientInfo={client} onStatusChange={updateOccStatus} />
      <br />

      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Letters Requested" value={settlementLettersRequested ? settlementLettersRequested : '0'} />
        <DisplayItem label="Price" value={Numeral(amount).format('$ ##,###0')} />
        <DisplayItem label="Amount Paid" value={Numeral(amountPaid).format('$ ##,###0')} />
        <DisplayItem label="PayMethod" value={paymentMethod ? paymentMethod : 'Not defined'} />
        {easyPayNumber && <DisplayItem type="easypay" clientId={clearancecertificate.clientId} label="EasyPay" value={easyPayNumber} />}
        {/* <DisplayItem label="Payment Due" value={paymentDueDay ? paymentDueDay : ' '} /> */}
        {previousDebtCounsellor && <DisplayItem label="DC Transfer" value={previousDebtCounsellor.fullName} />}
        {dcTransferStartedAt && <DisplayItem label="DC Transfer" value={dcTransferStartedAt ? moment(dcTransferStartedAt).format('Do MMMM YYYY') : ' '} />}
        {dcTransferCompletedAt && <DisplayItem label="Transfer Complete" value={dcTransferCompletedAt ? moment(dcTransferCompletedAt).format('Do MMMM YYYY') : ' '} />}
        {dcTransferRejectedAt && <DisplayItem label="Transfer Rejected" value={dcTransferRejectedAt ? moment(dcTransferRejectedAt).format('Do MMMM YYYY') : ' '} />}
        <DisplayItem label="Sold" value={createdAt ? moment(createdAt).format('Do MMMM YYYY') : ' '} />
        <DisplayItem label="Sold By" value={clearancecertificate.signupSalesInteraction ? clearancecertificate.signupSalesInteraction.salesPerson ? `${clearancecertificate.signupSalesInteraction.salesPerson.first} ${clearancecertificate.signupSalesInteraction.salesPerson.last}` : 'DIGITAL SALE' : clearancecertificate.accountingIdentifier ? clearancecertificate.accountingIdentifier : clearancecertificate.createdBy ? clearancecertificate.createdBy.description : 'DIGITAL SALEs'} />
      </Grid>
      <br />

      {/* <EditCC id={id} refetch={ccInformation} /> */}

      {(disablePDF || spin) && <center><ActivityIndicator /></center>}
      {/* <Grid container className={classes.center}>
        <Grid item xs={2} >
          <Button color="primary" disabled={disablePDF} onClick={() => downloadDoc("Certificate", clearancecertificate.id, clearancecertificate.certificateNumber)} >
            <FaIcons.FaDownload /> &nbsp;
            Certificate
          </Button>
        </Grid>
      </Grid> */}

      {/* <LivesCovered clearancecertificate={clearancecertificate} history={history} effectiveDate={effectiveDate} />
      <br />
      <Beneficiaries clearancecertificate={clearancecertificate} history={history} />
      <br /> */}

      <br />

      <ClientNotes clientId={clearancecertificate.clientId} productId={clearancecertificate.id} type={"Policy"} />
      <ProductCommunication clientId={clearancecertificate.clientId} productId={clearancecertificate.id} type={"CC"} />
      {clearancecertificate.numInstallments < 1 && <Button color="primary" onClick={() => sendOzowPaymentLink()}>Send Ozow Payment Link</Button>}
      <BankAccount {...clearancecertificate} paymentBankAccount={paymentBankAccount} paymentDay={clearancecertificate.paymentDay} clientId={clearancecertificate.clientId} productId={clearancecertificate.id} productType="CLEARANCE_CERTIFICATE" amount={numInstallments ? (amount / numInstallments) : amount} updateProduct={updateCc} />

      <Grid container justifyContent='flex-start' spacing={1}>
        <Grid item xs={8}> <PaymentHistory history={clearancecertificate.payments} /></Grid>
        <Grid item xs={4}><PolicyStatusHistory history={clearancecertificate.stateChanges} /></Grid>
      </Grid>

      <ClientDocuments clientId={clearancecertificate.clientId} />
      {/* <AllocationSummary summary={clearancecertificate.funeralAllocationSummary} product={"CC" }/> */}

      {cancel && <Grid container><Grid item xs={4}><br /><Button onClick={cancel}>Back</Button></Grid></Grid>}
    </Card>
  </>
  );
}


ClearanceCertificateInfo = withApollo(ClearanceCertificateInfo);
ClearanceCertificateInfo = graphql(ccInformation, {
  options: ({ id }) => {
    let variables = { id }
    return ({ variables })
  }
})(ClearanceCertificateInfo);

export default ClearanceCertificateInfo;


// some helpful mutations for admin
// processClearanceCertificateApplication(edited)
// this is when in application / client info required - it processes the application and advances it to next step
// meant for when have everything need
// dcTransferRejected - this is when the transfer is rejected - needs to be in state DC_TRANSFER_IN_PROGRESS
// dcTransferCompleted - this is when the transfer is completed successfully and then it moves to the next step
// cancelClearanceCertificate
// unCancelClearanceCertificate
// then there is the usual resolvers for comms, stateChanges, etc.