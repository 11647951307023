import React, { useState } from 'react'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import { ActivityIndicator, Button, Select, Card, TextField, FileUpload, Grid } from '../generic'

const useStyles = makeStyles((theme) => ({
  buttons: {
    justifyContent: 'flex-end',
  }
}));

const UNCANCEL_CC = gql`
	mutation UnCancelClearanceCertificate($clearanceCertificateId: String!, $unCancelReason: String) {
		unCancelClearanceCertificate(clearanceCertificateId: $clearanceCertificateId, unCancelReason: $unCancelReason){
        id
        state
      }
    }
`;

const CANCEL_CC = gql`
	mutation CancelClearanceCertificate($clearanceCertificateId: String!, $cancelReason: String) {
		cancelClearanceCertificate(clearanceCertificateId: $clearanceCertificateId, cancelReason: $cancelReason) {
        id
        state
      }
    }
`;

const CANCEL_GROUP_PRODUCT = gql`
  mutation CancelGroupProduct($groupProductId: String!, $cancelReason: String) {
    cancelGroupProduct(groupProductId: $groupProductId, cancelReason: $cancelReason) {
      id
      state
    }}
`

const UNCANCEL_GROUP_PRODUCT = gql`
  mutation UnCancelGroupProduct($groupProductId: String!, $unCancelReason: String) {
    unCancelGroupProduct(groupProductId: $groupProductId, unCancelReason: $unCancelReason) {
      id
      state
    }}
`

const UNCANCEL_FUNERAL = gql`
  mutation UnCancelFuneral($funeralId:  String!, $unCancelReason: String) {
    unCancelFuneral(funeralId: $funeralId, unCancelReason: $unCancelReason){
        id
        state
      }
    }
`;

const CANCEL_FUNERAL = gql`
  mutation CancelFuneral($funeralId:  String!, $cancelReason: String) {
    cancelFuneral(funeralId: $funeralId, cancelReason: $cancelReason) {
        id
        state
      }
    }
`;

const CREATE_NOTE = gql`
	mutation($input: CreateNoteInput! ) {
		createNote(input: $input) {
      id
      text
      filesIds
      associatedWith {
        type
        id
      }
      createdAt
      createdBy {
          description
        }
      updatedAt
      updatedBy {
        email
        description
      }
    }
	}
`;

const commonCancelOptions = [
  { value: 'Policyholder has claimed', label: 'Policyholder has claimed'},
  { value: 'Fraud Suspected', label: 'Fraud Suspected' },
  { value: 'Client Affordability', label: 'Client Affordability' },
  { value: 'Client changed their mind', label: 'Client changed their mind' },
  { value: 'Client Age', label: 'Client Age' },
  { value: 'Member removed from scheme', label: 'Member removed from scheme' },
  { value: 'Other', label: 'Other' }]

const commonUnCancelOptions = [
  { value: "Not Fraud", label: "Not Fraud" }, 
  { value: "Other", label: "Other" }]

const ccCancelOptions = [
  { value: "Fraud Suspected", label: "Fraud Suspected" }, 
  { value: "Duplicate", label: "Duplicate" }, 
  { value: "Client Affordability", label: "Client Affordability" }, 
  { value: "Client changed their mind", label: "Client changed their mind" }, 
  { value: "Can't get hold of Client", label: "Can't get hold of Client" }, 
  { value: "No longer interested",  label: "No longer interested" }, 
  { value: "DC Fees Outstanding",  label: "DC Fees Outstanding" }, 
  { value: "Existing DC cleared name", label: "Existing DC cleared name" },  
  { value: "Manually Issued – Joint Application", label: "Manually Issued – Joint Application" }, 
  { value: "Other", label: "Other" }]

const ccUnCancelOptions = [
  { value: "Not Fraud", label: "Not Fraud" }, 
  { value: "Cancelled Incorrectly", label: "Cancelled Incorrectly" }, 
  { value: "Client wants to resume process", label: "Client wants to resume process" }, 
  { value: "Other", label: "Other" }]

let Cancel = (props) => {
  const confirm = useConfirm();
  const { productType, productId, cancelUnCancel, clientId, setOpen, client } = props
  const [spin, setSpin] = useState(false)
  const [reason, setReason] = useState()
  const [text, setText] = useState()
  const [fileId, setFileId] = useState()
  const classes = useStyles();
  const isCancel = cancelUnCancel === 'CANCEL'

let productName, cancelOptions, unCancelOptions, cancelMutation, unCancelmutation, associatedWith, cancelVariables, unCancelVariables

if (productType === 'CLEARANCE'){
  productName = 'clearance certificate'
  cancelVariables = {
    clearanceCertificateId: productId,
    cancelReason: reason
  }
  unCancelVariables = {
    clearanceCertificateId: productId,
    unCancelReason: reason
  }
  cancelOptions = ccCancelOptions
  unCancelOptions = ccUnCancelOptions
  cancelMutation = CANCEL_CC
  unCancelmutation = UNCANCEL_CC
  associatedWith = [
    { type: 'Client', id: clientId }, 
    { type: 'ClearanceCertificate', id: productId }
  ] 
} else if (productType === 'FUNERAL'){
  productName = 'funeral policy'
  cancelVariables = {
    funeralId: productId,
    cancelReason: reason
  }
  unCancelVariables = {
    funeralId: productId,
    unCancelReason: reason
  }
  cancelOptions = commonCancelOptions
  unCancelOptions = commonUnCancelOptions
  cancelMutation = CANCEL_FUNERAL
  unCancelmutation = UNCANCEL_FUNERAL
  associatedWith = [
    { type: 'Client', id: clientId }, 
    { type: 'Funeral', id: productId }
  ] 
} else if (productType === 'GROUP_FUNERAL'){
  productName = 'group funeral policy'
  cancelVariables = {
    groupProductId: productId,
    cancelReason: reason
  }
  unCancelVariables = {
    groupProductId: productId,
    unCancelReason: reason
  }
  cancelOptions = commonCancelOptions
  unCancelOptions = commonUnCancelOptions
  cancelMutation = CANCEL_GROUP_PRODUCT
  unCancelmutation = UNCANCEL_GROUP_PRODUCT
  associatedWith = [
    { type: 'Client', id: clientId }, 
    { type: 'GroupProduct', id: productId }
  ] 
}

  const createNote = (input) => {
    console.log('create client note', {...input})
    client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
    .then(response => console.log('created note', response) )
  }

  const cancelThePolicy = () => {
    setSpin(true);
    console.log('cancelThePolicy create client note', text || fileId)

    if (text || fileId) {
      createNote({ 
        text, tags: ['Fraud'], 
        filesIds: [fileId], 
        associatedWith: [...associatedWith]
      })
    }
    console.log('cancelThePolicy props',productType, "ID:", productId, reason)

    client
      .mutate({
        mutation: cancelMutation,
        variables: cancelVariables
      })
      .then((response) => {
        setSpin(false);
        setOpen(false)
      })
  }

  const unCancelThePolicy = () => {
    setSpin(true)
    console.log("ProductId: ", productId, productName, productType)
    if (text || fileId) {
      createNote({ 
        text, tags: ['Fraud'], 
        filesIds: [fileId], 
        associatedWith: [...associatedWith]
          })
    }

    client
      .mutate({
      mutation: unCancelmutation,
      variables: unCancelVariables
      })
      .then((response) => {
        setSpin(false);
        setOpen(false)
      })
  }

  const extraConfirm = () => confirm({
    title: 'Are you really sure',
    cancellationButtonProps: { variant: 'contained', color: 'default' },
    confirmationButtonProps: { variant: 'contained', color: 'primary' },
    description: `Are you sure you want to ${cancelUnCancel === 'CANCEL' ? 'CANCEL' : 'REINSTATE'} the ${productName}?`
  })
    .then(() => {
      if (cancelUnCancel === 'CANCEL') {
        cancelThePolicy()
      }
      else {
        unCancelThePolicy()
      }
    })

  return (
    <Card>
      {( spin) && <center><ActivityIndicator /></center>}
      {isCancel
        ? <h1><b>Warning: Cancelling is final!</b></h1>
        : <h1><b>The {productName} will be reinstated</b></h1>}
      {/* <h2>A refund will be issued if due.</h2> */}
      <Select
        style={{ width: 600 }}
        fullWidth
        autoFocus
        label={`Why are you ${isCancel ? 'cancelling' : 'uncancelling'}`}
        options={isCancel ? cancelOptions : unCancelOptions}
        onChange={(v) => { console.log('reason selected is ', v.target.value); setReason(v.target.value)}}
        placeholder="Select a reason"
        value={reason}
      />
      <br />
      {((reason && reason.indexOf('Fraud') > -1) || !isCancel)
        ? <Grid container spacing={1}>
            <Grid item xs={10}>
            <TextField
              margin="dense"
              label="Supporting information"
              fullWidth
              value={text}
              onChange={(v) => { setText(v.target.value)} }
            />
          </Grid>
            <Grid item xs={1}>
              <FileUpload value={fileId} onChange={setFileId} />
            </Grid>
        </Grid>
        : <><br /> </>}

      <Grid container className={classes.buttons} spacing={3}>
        <Grid item xs={2}>
          <Button  onClick={() => setOpen(false)} >Cancel </Button>
        </Grid>

        <Grid item xs={2}>
          <Button color="primary" disabled={((!text && (reason && reason.indexOf('Fraud') > -1) )) || !reason || spin} onClick={() => extraConfirm()}>
            Confirm
          </Button>
        </Grid>
      </Grid>
      <br />
    </Card>
  )
}

export default withApollo(Cancel)
