import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, Grid, DisplayItem, Modal, Switch } from '../generic';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import SelectBankAccount from './SelectBankAccount';
import { withApollo, useQuery } from 'react-apollo';
import gql from 'graphql-tag'
import BankInfo from './BankInfo'
import Mandates from './Mandates'
import BANKS from '../../variables/banks'
import { ActivityIndicator, VerifyDoc, TextInput } from '../generic'
import { useConfirm } from "material-ui-confirm";
import { printCurrency } from '../../lib/helpers';

const useStyles = makeStyles((theme) => ({
  field: {
    display:"flex",
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection:"column"
  },
  flexStart: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingBottom: 7
  },
  flexSpace: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'space-around',
    // paddingBottom: 7
  },
  button: {
    color: '#262626',
    padding: '0',
  }
}));

const paymentTypeFromBranch = (branchCode) => {
  let result = "DO"
  BANKS.forEach(bank => {
    if (bank.value == branchCode) {
      result = bank.paymentType
    }
  })
  return result
}

const bankNameFromBranch = (branchCode, bankName) => {
  let result = `${bankName} *Error*`
  BANKS.forEach(bank => {
    if (bank.value == branchCode) {
      result = bank.label
    }
  })
  return result
}

const DEBIT_ORDER = gql`
	query DebitOrder($id: String!) {
		debitorder(id: $id) {
      id
      paymentType
      amount
      nextDebitDate
      bankAccount {
        clientId
        groupId
        id
        bankName
        branchCode
        accountNo
      }
      debitOrderMandateInvalid
      debitOrderMandateNotRequired
      isActive
      lastRunAt
      runToday
      runAsDebitOrder
      payDay 
      createdAt
      updatedAt
      debitOrderMandate {
        id
        url
        name
        updatedAt
      }
      mandates {
        authenticationType
        mandateType
        status
        code
        description
        updatedAt
      }
    }
  }`

const CREATE_RECURRING_DEBIT_ORDER = gql`
  mutation CreateRecurringProductDebitOrder( $productAmount: ProductAmountInput, $bankAccountId: ObjID, $paymentType: PaymentType, $payDay: Int) {
    createRecurringProductDebitOrder(productAmount: $productAmount, bankAccountId: $bankAccountId, paymentType: $paymentType, payDay: $payDay) {
      id
      debitOrderMandateInvalid
      debitOrderMandateNotRequired
      isActive
      lastRunAt
      amount
      runToday
      runAsDebitOrder
      payDay
      debitOrderMandate {
        id
        url
        name
        updatedAt
      }
      paymentType
      associatedProducts {
        productId
        productType
      }
      mandates {
        authenticationType
        status
        code
        description
        updatedAt
      }
    }
  }`

const UPDATE_DEBIT_ORDER = gql`
  mutation updateDebitOrder( $id: String!, $input: UpdateDebitOrderInput!) {
    updateDebitOrder(id: $id, input: $input) {
      id
      debitOrderMandateInvalid
      debitOrderMandateNotRequired
      isActive
      amount
      lastRunAt
      runToday
      runAsDebitOrder
      payDay
      debitOrderMandate {
        id
        url
        name
        updatedAt
      }
      paymentType
      associatedProducts {
        productId
        productType
      }
      mandates {
        authenticationType
        status
        code
        description
        updatedAt
      }
    }
  }`

const GET_BANK_ACCOUNT = gql`
  query BankAccount( $id: String!) {
    bankaccount(id: $id) {
      id
      bankName
      accountNo
      branchCode
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
      verifyAccountInfo
      clientId
      groupId
      }
  }`

const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount( $id: String!, $input: UpdateBankAccountInput!) {
    updateBankAccount(id: $id, input: $input) {
      id
      clientId
      groupId
      bankName
      branchCode
      accountNo
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
      verifyAccountInfo
    }
  }`

const CREATE_NOTE = gql`
	mutation($input: CreateNoteInput! ) {
		createNote(input: $input) {
      id
      text
      filesIds
      associatedWith {
        type
        id
      }
      createdAt
      createdBy {
          description
        }
      updatedAt
      updatedBy {
        email
        description
      }
		}
	}
`;

const newMandateAllowed = (debitorder, paymentBankAccount) => {
  const { isActive, debitOrderMandate, mandates, paymentType, debitOrderMandateInvalid, debitOrderMandateNotRequired, bankAccount, collectToBankAccount } = debitorder || {}

  if (!debitorder || !paymentBankAccount) {
    return true
  }

  else if (bankAccount && paymentBankAccount && bankAccount.id.toString() !== paymentBankAccount.id.toString()) {
    return true
  }

  else if (paymentType === 'NAEDO') {
    return true
  }

  else if (mandates) {
    const completed = mandates.find(m => m.status === 'COMPLETED')
    return completed ? true : false
  }

  else if (!mandates) {
    return true
  }

  else {
    return (debitOrderMandate && debitOrderMandate.url && !debitOrderMandateInvalid && !debitOrderMandateNotRequired ? false : true)
  }
}

const haveMandate = (debitorder, paymentBankAccount) => {
  const { isActive, debitOrderMandate, mandates, paymentType, debitOrderMandateInvalid, debitOrderMandateNotRequired, bankAccount, collectToBankAccount } = debitorder || {}

  if (!isActive) {
    return false
  }

  else if (isActive) {
    return true
  }

  else if (!debitorder || !paymentBankAccount) {
    return false
  }

  else if (bankAccount && paymentBankAccount && bankAccount.id.toString() !== paymentBankAccount.id.toString()) {
    return false
  }

  else if (paymentType === 'NAEDO') {
    return false
  }

  else if (mandates) {
    const completed = mandates.find(m => m.status === 'COMPLETED')
    return completed ? true : false
  }

  // else if (!mandates) {
  //   return false
  // }

  else {
    return false
  }
}

function BankAccount({ debitOrderId, productId, productType, amount: mandateAmount, updateProduct, clientId, groupId, client, paymentDay, bankAccounts, disabled = false, allowEdit = true, ...rest }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [paymentBankAccount, setPaymentBankAccount] = useState()
  const [showEditBank, setShowEditBank] = useState(false)
  const [showBankSelect, setShowBankSelect] = useState(false)
  const [showRunToday, setShowRunToday] = useState(false)
  const [runAvs, setRunAvs] = useState(false)
  const [reason, setReason] = useState()
  const [showDoMandate, setShowDoMandate] = useState(false)
  const [enableNewMandateButton, setEnableNewMandateButton] = useState(false)
  const [showActivity, setShowActivity] = useState(false)
  const [doFile, setDoFile] = useState()
  const [debiCheckBank, setDebiCheckBank] = useState(paymentBankAccount ? paymentTypeFromBranch(paymentBankAccount.branchCode) === 'DEBI_CHECK' : false)
  const [avsDetails, setAvsDetails] = useState(rest.paymentBankAccount || {})
  const [showCreateMandate, setShowCreateMandate] = useState(false)
  const [newPayDay, setNewPayDay] = useState(null)

  const { data, loading, error } = useQuery(DEBIT_ORDER, {
    variables: { id: debitOrderId },
    skip: !debitOrderId,
  });

  const { data: bankData, loading2, error2 } = useQuery(GET_BANK_ACCOUNT, {
    variables: { id: rest.paymentBankAccount ? rest.paymentBankAccount.id : '' },
    skip: !(rest && rest.paymentBankAccount),
  });

  useEffect(() => {
    setPaymentBankAccount(bankData && bankData.bankaccount)
  }, [bankData]);

  if (loading || loading2) {
    return <ActivityIndicator />
  }

  const { debitorder } = data || {}
  const { bankAccount, amount, nextDebitDate, lastRunAt, runToday, runAsDebitOrder, mandates, debitOrderMandate, paymentType, isActive } = debitorder || {}
  let { payDay } = debitorder || {}
  const { avsRawData, idNumberMatch, nameMatch, phoneMatch, accountExists } = avsDetails || {}

  let nextDebitOrderRun = ""
  if (isActive) {
    if (runToday) {
      const now = moment()
      const midday = moment().hour(12).minute(0).second(0).millisecond(0)      
      nextDebitOrderRun = now.isAfter(midday) ? now.add(1, 'day').format('DD-MM-YYYY') : now.format('DD-MM-YYYY')
    } else {
      nextDebitOrderRun = nextDebitDate ? moment(nextDebitDate).format('DD-MM-YYYY') : ""
    }
  }

  const freedom = productType === 'FUNERAL' && rest.policyNumber && rest.policyNumber.indexOf('MF') === -1

  const recurringDebitOrderPrompt = (input) => {
    const { payDay, productId, productType, amount } = input

    if (!payDay) {
      return setShowCreateMandate(true)
    }

    confirm({
      title: 'Create New Mandate',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `${debiCheckBank
        ? 'Tell the client that the bank will send a debi check mandate 10 - 15 seconds after you press "OK"'
        : `Do you want to create a new mandate${amount ? ` of R${amount}`:''}, on ${bankNameFromBranch(branchCode, bankName)} ${accountNo} `
        }`
    })
      .then(() => updateRecurringDebitOrder(input))
      .catch(() => { })
  }

  const createNote = (input) => {
    client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
      .then(() => setReason())
  }

  const updateBankAccount = (input) => {
    setShowActivity(true)

    client.mutate({
      mutation: UPDATE_BANK_ACCOUNT,
      variables: {
        id: paymentBankAccount.id,
        input
      }
    })
      .then((result) => {
        setShowActivity(false)
        if (input.verifyAccountInfo === false) {
          console.log('rerun avs', reason, result)
          if (reason) {
            const associatedWith = bankAccount.clientId ? [{ type: 'Client', id: bankAccount.clientId }, { type: productType, id: productId }] : [{ type: productType, id: productId }]
            createNote({ text: reason, tags: ['reRun AVS'], associatedWith })
          }
          updateBankAccount({ verifyAccountInfo: true })
        }
        else {
          setAvsDetails(result.data.updateBankAccount)
          setPaymentBankAccount(result.data.updateBankAccount)

        }
      })
      .catch((error) => {
        setShowActivity(false)
        console.log('there was an error', error)
      });
  }

  const updateDebitOrder = (input, tags) => {
    setShowActivity(true)
    setShowRunToday(false)

    if (paymentType === 'NAEDO') {
      input.paymentType = 'DEBI_CHECK'
    }

    client.mutate({
      mutation: UPDATE_DEBIT_ORDER,
      variables: {
        id: debitOrderId,
        input
      }
    })
      .then((result) => {
        // console.log('result.data.createRecurringProductDebitOrder', result.data.createRecurringProductDebitOrder)
        // setDoDetails({ ...debitorder, ...input })
        setShowActivity(false)
        const associatedWith = bankAccount.clientId ? [{ type: 'Client', id: bankAccount.clientId }, { type: productType, id: productId }] : [{ type: productType, id: productId }]
        result.data.updateDebitOrder.associatedProducts && result.data.updateDebitOrder.associatedProducts.length > 0 && result.data.updateDebitOrder.associatedProducts.forEach(p => {
          associatedWith.push({ type: p.productType, id: p.productId })
        })
        if (reason) {
          createNote({ text: reason, tags, associatedWith })
        }
      })
      .catch((error) => {
        setShowActivity(false)
        console.log('there was an error', error)
      });
  }

  const updateRecurringDebitOrder = ({ id: bankAccountId, debiCheckBank, payDay, productId, productType, amount }) => {
    // const paymentType = debiCheckBank === true ? 'DEBI_CHECK' : 'EFT'

    let productAmount = {
      productId,
      productType,
    }

    if (productType === "CREDIT_LIFE" || productType === "GROUP_PRODUCT") {
      productAmount.calculateAmount = true
    } else {
      productAmount.amount = amount
    }

    setShowActivity(true)
    setEnableNewMandateButton(true)

    client.mutate({
      mutation: CREATE_RECURRING_DEBIT_ORDER,
      variables: {
        productAmount,
        bankAccountId,
        // paymentType,
        payDay
      }
    })
      .then((result) => {
        console.log('result.data.createRecurringProductDebitOrder', result.data.createRecurringProductDebitOrder)
        // setDoDetails(result.data.createRecurringProductDebitOrder)
        updateProduct({ debitOrderId: result.data.createRecurringProductDebitOrder.id })
        setEnableNewMandateButton(newMandateAllowed(result.data.createRecurringProductDebitOrder, paymentBankAccount))
        setShowActivity(false)
      })
      .catch((error) => {
        setShowActivity(false)
        console.log('there was an error', error)
      });
  }

  if (!payDay) {
    payDay = paymentDay
  }
  const latestMandate = mandates && mandates.length > 0 && mandates.filter(m => m.updatedAt).sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1)
  const { updatedAt } = latestMandate && latestMandate[0] || {}
  const title = paymentType ? paymentType.toUpperCase() : ""
  // const bankTitle = bankAccount ? ` - ${bankAccount.bankName} branch: ${bankAccount.branchCode} Acc: ${bankAccount.accountNo}` : 'No Payment Bank Assigned'
  const { id, ownAccount, accountHolderName, bankName, branchCode, accountNo, accountHolderIdNumber, accountHolderContactNumber, accountHolderEmail, verifyAccountInfo } = paymentBankAccount || {}
  const validMandate = haveMandate(debitorder, paymentBankAccount)

  const missedTheDeadline = new Date().getHours() >= 12
  return (
    <>
      {paymentBankAccount ?
        <>
          <br />
          {allowEdit
            ? <IconButton aria-label="edit" className={classes.button} onClick={() => setShowEditBank(true)} >
              <span style={{ fontSize: 21, fontWeight: 400 }}>Payment Bank Account</span>
              &nbsp;
              <EditIcon fontSize='small' />
            </IconButton>
            : <span style={{ fontSize: 21, fontWeight: 400 }}>Payment Bank Account</span>}
          <Grid container className={classes.flexStart} spacing={1}>
            <DisplayItem label="Bank" value={`${bankNameFromBranch(branchCode, bankName)} ${accountNo}`} xs={3} />
            {ownAccount === false && <DisplayItem label="Holder" value={accountHolderName} xs={2} />}
            {ownAccount === false && <DisplayItem label="IdNumber" value={accountHolderIdNumber} xs={1} />}
            {ownAccount === false && <DisplayItem label="Mobile" value={accountHolderContactNumber} xs={1} />}
            {ownAccount === false && <DisplayItem label="Email" value={accountHolderEmail} xs={1} />}
            <Grid item xs={1}><Button disabled={showBankSelect || disabled || allowEdit === false} onClick={() => setShowBankSelect(true)}>New</Button></Grid>
            <Grid item xs={3}>
              {avsRawData === null ?
                <Grid item xs={1}>
                  <>
                    {!verifyAccountInfo ? <Button disabled={disabled} onClick={() =>
                      confirm({
                        description: `Run AVS against bank account?`,
                        title: `Account Verification Service`,
                        cancellationButtonProps: { variant: 'contained', color: 'default' },
                        confirmationButtonProps: { variant: 'contained', color: 'primary' }
                      }).then(() => updateBankAccount({ verifyAccountInfo: true }))} >AVS
                    </Button>
                      : <Button disabled={disabled || allowEdit === false} onClick={() => setRunAvs(true)} >RERUN_AVS
                      </Button>}
                  </>
                </Grid>
                : <div style={{ boxShadow: '0px 2px 4px rgba(0,0,0,0.2)', backgroundColor: '#FAFAFA', paddingTop: 7, paddingBottom: 7 }}>
                  <Grid container className={classes.flexSpace} spacing={1}>
                    <Grid item xs={2} >
                      AVS
                    </Grid>
                    <Grid item xs={2} >
                      Id
                    </Grid>
                    <Grid item xs={2} >
                      Name
                    </Grid>
                    <Grid item xs={2} >
                      Phone
                    </Grid>
                    <Grid item xs={2} >
                      Account
                    </Grid>
                  </Grid>
                  <Grid container className={classes.flexSpace} spacing={1}>
                    <Grid item xs={2} >
                      Result
                    </Grid>
                    <Grid item xs={2} >
                      {<i className={`far ${idNumberMatch !== null && 'fa-2x'} fa-${idNumberMatch === null ? 'question' : idNumberMatch ? 'check' : 'times'}-circle`} style={{ color: idNumberMatch === null ? 'grey' : idNumberMatch ? 'green' : 'red' }} />}
                    </Grid>
                    <Grid item xs={2} >
                      {<i className={`far ${nameMatch !== null && 'fa-2x'} fa-${nameMatch === null ? 'question' : nameMatch ? 'check' : 'times'}-circle`} style={{ color: nameMatch === null ? 'grey' : nameMatch ? 'green' : 'red' }} />}
                    </Grid>
                    <Grid item xs={2} >
                      {<i className={`far ${phoneMatch !== null && 'fa-2x'} fa-${phoneMatch === null ? 'question' : phoneMatch ? 'check' : 'times'}-circle`} style={{ color: phoneMatch === null ? 'grey' : phoneMatch ? 'green' : 'red' }} />}
                    </Grid>
                    <Grid item xs={2} >
                      {<i className={`far ${accountExists !== null && 'fa-2x'}  fa-${accountExists === null ? 'question' : accountExists ? 'check' : 'times'}-circle`} style={{ color: accountExists === null ? 'grey' : accountExists ? 'green' : 'red' }} />}
                    </Grid>
                  </Grid>
                </div>
              }
            </Grid>
          </Grid>

          {bankAccount
            ? <Card>
              <h3>Payment Information</h3>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                <DisplayItem label="Type" value={title} xs={1} />
                <DisplayItem label="Bank" value={bankAccount.bankName} xs={1} />
                {/* <DisplayItem label="Branch" value={bankAccount.branchCode} xs={1} /> */}
                <DisplayItem label="Account" value={bankAccount.accountNo} xs={1} />
                <DisplayItem label="Day" value={payDay} xs={1} />
                {/* <DisplayItem label="Mandate" value={mandateAmount ? printCurrency(mandateAmount) : ''} xs={1} /> */}
                <DisplayItem label="Last Run" value={debitorder ? lastRunAt ? moment(lastRunAt).format('DD-MM-YYYY hh:mm a') : "Never" : ' '} xs={2} />
                <DisplayItem label="Next Run Amount" value={isActive ? amount : ""} xs={2} />
                <DisplayItem label="Next Run Date" value={nextDebitOrderRun} xs={2} />
                <Grid item xs={1} className={classes.field}>
                    <span>Is Active</span>
                    <Switch value={isActive ? true : false} onChange={() => updateDebitOrder(({ isActive: !isActive }))}/>
                </Grid>
                <DisplayItem label="runAsDebitOrder" value={runAsDebitOrder ? 'Yes' : 'No'} xs={1} />
                <DisplayItem label="Run Today" value={debitorder ? runToday ? "YES" : "NO" : ''} xs={1} />
                <Grid item xs={1}>
                  <Switch disabled={false && (!debitorder || !validMandate)} value={runToday ? true : false}
                    onChange={() => setShowRunToday(true)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button disabled={(disabled || allowEdit === false || (updatedAt && moment().diff(updatedAt, 'minutes') < 1))} onClick={() => recurringDebitOrderPrompt({ id, debiCheckBank, payDay, productId, productType: productType === 'OPEN_MARKET' ? 'CREDIT_LIFE' : productType, amount })}>New_Man</Button>
                </Grid>
              </Grid>

              <Mandates mandates={mandates} validMandate={validMandate} debitOrderMandate={debitOrderMandate} />

              {showActivity && <ActivityIndicator />}
            </Card>
            : <div><h3>Payment Information</h3>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item xs={3}>
                  No Debit Order found
                </Grid>
                {productType !== 'CLAIM' && <Grid item xs={1}>
                  <Button disabled={(disabled || allowEdit === false || (updatedAt && moment().diff(updatedAt, 'minutes') < 1))} onClick={() => recurringDebitOrderPrompt({ id, debiCheckBank, payDay, productId, productType: productType === 'OPEN_MARKET' ? 'CREDIT_LIFE' : productType, amount: amount || mandateAmount })}>
                    New
                  </Button>
                </Grid>}
              </Grid>
            </div>
          }
        </>
        : <Grid container justifyContent="start" alignItems='center'>
          <Grid item xs={2} >
            <div style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
              Bank Account
            </div></Grid>
          {/* <Grid xs={1} >No bank defined</Grid> */}
          <Grid item xs={5} ><Button disabled={disabled || allowEdit === false || showBankSelect || !(clientId || groupId)} onClick={() => setShowBankSelect(true)}>Associate Bank </Button>{`${clientId || groupId ? ' ' : ' (Disabled as beneficiary has no Id Number)'}`} </Grid>
        </Grid>}

      {open && <VerifyDoc
        description="Debit order mandate"
        file={doFile}
        setOpen={() => setShowDoMandate(false)}
        open={showDoMandate}
        noButtons
      />
      }
      <Modal
        title={'Edit Bank Details'}
        okText="Ok"
        cancelText="Back"
        wrapClassName="vertical-center-modal"
        open={showEditBank}
        onClose={setShowEditBank}
        noButtons
      >
        <BankInfo clientId={clientId} groupId={groupId} id={id} showButtons={false} cancel={() => setShowEditBank(false)} />

      </Modal>

      <Modal
        title={'Select or Create Bank'}
        okText="Ok"
        cancelText="Back"
        open={showBankSelect}
        onClose={setShowEditBank}
        noButtons
        maxWidth='md'
      >
        <SelectBankAccount bankAccounts={bankAccounts} clientId={clientId} groupId={groupId} productId={productId} bankId={id}
          updateBank={(bank) => {
            console.log('update bank', bank);
            setPaymentBankAccount(bank);
            setShowBankSelect(false);
            updateProduct({ paymentBankAccountId: bank.id })
          }}
          cancel={() => setShowBankSelect(false)} />

      </Modal>

      <Modal
        title={`Are you sure you want to ${runToday ? 'cancel sending the payment' : 'run the payment manually?'}`}
        okText="Ok"
        cancelText="Back"
        open={showRunToday}
        onClose={() => setShowRunToday(false)}
        onCancel={() => setShowRunToday(false)}
        onOk={() => reason && updateDebitOrder({ runToday: !runToday }, [`${runToday ? 'Cancel runToday' : 'runToday'}`])}
        maxWidth='md'
        disabled={!reason}
      >

        <span style={{ marginTop: 21, fontWeight: 400, fontSize: 18 }}>Enter a note to explain your reason for doing this</span>

        <TextInput fullWidth value={reason} label="Note" placeholder="Note" id="runreason"
          onChange={e => setReason(e.target.value)} />
        <br />
        <br />
        <br />

        <span style={{ fontWeight: 600, fontSize: 18 }}>{runToday ? "Today's payment will be cancelled" : missedTheDeadline ? `You are too late for today, the payment will be sent tomorrow at 12 noon` : 'The payment will be submitted at 12 noon today'}</span>
      </Modal>

      <Modal
        title={`Rerun the AVS`}
        okText="Ok"
        cancelText="Back"
        open={runAvs}
        onClose={() => setRunAvs(false)}
        onCancel={() => setRunAvs(false)}
        onOk={() => {
          updateBankAccount({ verifyAccountInfo: false });
          // updateBankAccount({ verifyAccountInfo: true });
          setRunAvs(false)
        }
        }
        maxWidth='md'
        disabled={!reason}
      >

        <span style={{ marginTop: 21, fontWeight: 400, fontSize: 18 }}>Enter a note to explain your reason for doing this</span>

        <TextInput fullWidth value={reason} label="Note" placeholder="Note" id="runreason"
          onChange={e => setReason(e.target.value)} />
        <br />
      </Modal>

      <Modal
        title={'Set Pay date for Mandate'}
        okText="Ok"
        cancelText="Back"
        open={showCreateMandate}
        onClose={setShowCreateMandate}
        onCancel={() => setShowCreateMandate(false)}
        noButtons
        maxWidth='md'
      >

        <Grid container className={classes.field} spacing={1}>
          <DisplayItem value={`${bankNameFromBranch(branchCode, bankName)} ${accountNo}`} xs={3} />
          <TextInput type="number" label="Pay Day" id="payday" placeholder='eg. 23'
          onChange={e => setNewPayDay(e.target.value)} />
          <Grid item xs={5}><Button onClick={() => {
          updateProduct({ paymentDay:newPayDay, paymentMethod: paymentTypeFromBranch(paymentBankAccount.branchCode) })
          setShowCreateMandate(false)
          recurringDebitOrderPrompt({ id, debiCheckBank, payDay: newPayDay, productId, productType: productType === 'OPEN_MARKET' ? 'CREDIT_LIFE' : productType, amount: amount || mandateAmount })
          }}>Create</Button>
           <Button onClick={() => {
            setShowCreateMandate(false)
          }}>Close</Button></Grid>
        </Grid>

      </Modal>
    </>
  )
}

export default withApollo(BankAccount)
