import React, { Component, useState, useEffect } from "react";
import { useQuery, Query, Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { Switch, Grid, Card } from '../generic'
import WhatsAppMessages from './WhatsappMessages'
const images = require('../../assets/images')
import { R4_WHATSAPP_NUMBER } from '../../config'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingRight: 10,
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    "& .MuiItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
    "& .MuiListItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
  },
  list: {
    minHeight: '80vh',
    width: '100%',
    // maxWidth: 360,
    overflow: 'auto',
    border: '1px solid #D1DBE3!important',
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    width: '100%',
    marginTop: 10,
    border: '1px solid #D1DBE3',
    backgroundColor: '#F6FBFF',
  },
  needsReply: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  bold: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  active: {
    backgroundColor: '#C6E3FA!important'
  },
  read: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: '400!important',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 3
  },
}));

const ALL_CONTACTS = gql`
  query CommunicationContactList($filter: CommunicationFilter!) {
    communicationContactList(filter: $filter){
      displayName
      number
      hasUnread
      lastReply
      lastReceipt
      clientId
      client {
        firstNames
        surname
        idNumber
      }
    }
  }
`

//"react-apollo": "^1.4.15",
const NEW_COMMUNICATION = gql`
  subscription {
  communicationCreated {
    id
    client {
      id
      firstNames
      surname
      idNumber
      cellNumbers
    }
    type
    to
    from
    name
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
    attachments {
      fileName
      fileId
      file {
        id
        contentType
      }
    }
  }
}`;

const WHATSAPP_CLOUD_NUMBER = "+27606337528" 

let WhatsAppContacts = ({communicationContactList}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState({})
  const [unreadOnly, setUnreadOnly] = useState(true)
  const [allContacts, setAllContacts] = useState([])
  const [contacts, setContacts] = useState([])
  const [from, setFrom] = useState('')

  useEffect(() => {
    setAllContacts(communicationContactList && communicationContactList.length > 0 ? communicationContactList
      .filter(a => Math.max(a.lastReceipt, a.lastReply) >= moment().subtract(5, 'days').valueOf())
      .sort((a, b) => ((Math.max(a.lastReceipt, a.lastReply)) < (Math.max(b.lastReceipt, b.lastReply))) ? 1 : -1) : [])
  }, [communicationContactList])

  useEffect(() => {
    if (unreadOnly) {
      setContacts(allContacts
        .filter(c => unread(c) && (c.number != WHATSAPP_CLOUD_NUMBER) && (c.number != R4_WHATSAPP_NUMBER))
        .sort((a, b) => ((Math.max(a.lastReceipt, a.lastReply)) < (Math.max(b.lastReceipt, b.lastReply))) ? 1 : -1)
      )
    } else {
      setContacts(allContacts
        .filter(c => c.number != WHATSAPP_CLOUD_NUMBER && c.number != R4_WHATSAPP_NUMBER)
        .sort((a, b) => ((Math.max(a.lastReceipt, a.lastReply)) < (Math.max(b.lastReceipt, b.lastReply))) ? 1 : -1)
    )
    }
  }, [allContacts, unreadOnly])

  useEffect(() => {
    setFrom(selected && selected.number ? selected.number : '')
  }, [selected])

  const unread = (contact) => {
    const lastReply = contact.lastReply
    const lastReceipt = contact.lastReceipt
    return (!lastReply || !lastReceipt || lastReply < lastReceipt)
  }

  return (
    <Card>
      <Grid container className={classes.root}>
        <Grid item xs={3}>
          <Switch value={unreadOnly} onChange={() => setUnreadOnly(!unreadOnly)} label={unreadOnly ? `Unreplied Chats (${contacts.length})` : `All chats (${allContacts.length})`} />
        </Grid>
        <Grid item xs={9}  >
          {selected.number && <List className={classes.top}>
            <ListItem >
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${selected.name} ${selected.idNumber} ${selected.id}`} />
            </ListItem>
          </List>}
        </Grid>
        <Grid item xs={3} >
          <List style={{ maxHeight: '300px', overflow: 'auto' }} className={classes.list}>
              {contacts && contacts.length > 0 && contacts.map(c => {
                const noReply = unread(c)
                return (
                  <ListItem key={c.number}
                    className={noReply ? classes.needsReply : classes.read}
                    onClick={() => setSelected({ ...c, name: c.client ? `${c.client.firstNames} ${c.client.surname}` : `${c.displayName}`, idNumber: c.client ? c.client.idNumber : '', id: c.client ? c.clientId : '' })} 
                    selected={c.id === selected.id}
                    >
                    <ListItemText primary={`${c.client ? `${c.number ? c.number : ''} ${c.client.firstNames} ${c.client.surname}` : `${c.number ? c.number : ''} ${c.displayName ? c.displayName : 'No Name Provided'}`} `} />
                </ListItem>
                )
              })}
          </List>
        </Grid>
        <Grid item xs={9}>
          {selected.number && <WhatsAppMessages clientId={selected.client ? selected.clientId : undefined} client={selected.client? selected.client : undefined} from={selected.number} name={selected.name ? selected.name : undefined} 
           />}
        </Grid>
      </Grid>
    </Card>
  )
}

const ChatAdmin = () => {
  const {
    data,
    loading,
    error,
    subscribeToMore
  } = useQuery(ALL_CONTACTS, { variables: { filter: { type: "WHATSAPP" } } });

  useEffect(() => {
    if (data) {
      const unsubscribe = subscribeToMore({
        document: NEW_COMMUNICATION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newMessage = subscriptionData.data.communicationCreated

          if (newMessage.type !== "WHATSAPP") {
            return prev 
          }

          const newMessageClient = newMessage.client

          let updatedContactList = []

          let hasUpdated = false
          const isReply = !newMessage.from || newMessage.from.includes('meerkat') || newMessage.from.includes(R4_WHATSAPP_NUMBER) || newMessage.from.includes(WHATSAPP_CLOUD_NUMBER) 

          for (let contact of prev.communicationContactList) {
            // check if matches and update
            if ((contact.number === newMessage.from) || (contact.number === newMessage.to)) {
              hasUpdated = true
              if (isReply) {
                updatedContactList.push({
                  ...contact,
                  hasUnread: false,
                  lastReply: newMessage.createdAt
                })
              } else {
                updatedContactList.push({
                  ...contact,
                  hasUnread: true,
                  lastReceipt: newMessage.createdAt
                })
              }
            } else {
              updatedContactList.push(contact)  
            }
          }

          if (!hasUpdated) {
            updatedContactList.push({
              displayName: newMessage.name,
              number: isReply ? newMessage.to : newMessage.from,
              hasUnread: !isReply,
              lastReply: isReply ? newMessage.createdAt : null,
              lastReceipt: !isReply ? newMessage.createdAt : null,
              client: newMessageClient ? {
                firstNames: newMessageClient.firstNames,
                surname: newMessageClient.surname,
                idNumber: newMessageClient.idNumber,
                "__typename": "Client"
              } : null,
              clientId: newMessageClient ? newMessageClient.id : null,
              "__typename": "CommunicationContact"
            })
          }

          return {
            ...prev,
            communicationContactList: updatedContactList,
          };
        },
      });
      return () => unsubscribe();
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>`Error! ${error.message}`</p>;

  return (
    <div>
      <WhatsAppContacts communicationContactList={data.communicationContactList} />
    </div>
  );
};

export default ChatAdmin