import React, { useState, Fragment } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Terms from './Terms';
import Cancel from '../shared/Cancel'
import BankAccount from '../bankAccount/BankAccount';
import {  ClientInformation, ClientDocuments, ClientNotes } from '../shared'
import ProductCommunication from '../communication/ProductCommunication'

import * as FaIcons from "react-icons/fa";
import { ActivityIndicator, Button, Card, FormModal, Modal, Grid, DisplayItem } from '../generic'
import GraceDays from '../generic/GraceDays'
import Quote from './Quote'
import EditFuneral from './EditFuneral'
import LivesCovered from './LivesCovered'
import Beneficiaries from './Beneficiaries'
import PolicyStatusHistory from '../credit-life/PolicyStatusHistory'
import PaymentHistory from '../credit-life/PaymentHistory'
import fileDownload from 'js-file-download'
import { getQuoteAmount } from '../../lib/calculatePrice'
import { API_URL } from '../../config';
import { differenceInDays } from 'date-fns'
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import GroupFuneralInfo from './GroupFuneralInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    marginLeft: 3,
    marginTop: 7,
    // position: 'absolute',
    // top: 9, left: '8em'
    // color: '#fff',
    fontSize: 21,
    fontWeight: "bold"
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingTop: 7,
    paddingBottom: 7
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
}));

// switch between locales
Numeral.locale('en-za')

export const friendlyType = (type) => type ? type.replace(/_/g, "").replace(/AND/g, "+").replace(/WITH/g, "+") : ''

export const translateState = (value) => {
  switch (value) {
    case 'INFO_MISSING' || 'MISING_INFO': return 'INCOMPLETE APPLICATION'
      break
    case 'DORMANT': return "POLICY ISSUED"
      break
    case 'NOT_TAKEN_UP': return 'NOT TAKEN UP'
      break
    case 'LAPSED': return 'LAPSED'
      break
    case 'ACTIVE': return 'ON RISK'
      break
    case 'GRACE': return "ON RISK GRACE"
      break
    case 'ON_RISK_GRACE': return "ON RISK GRACE"
      break
    case 'CLAIMED': return 'CLAIMED'
      break
    case 'CANCELLED': return 'CANCELLED '
      break
    default: return 'UNKNOWN'
      break
  }
}

export const EditModal = (props) => <FormModal componentProps={props} />

const funeralFragment = gql`
  fragment FuneralFragment on Funeral {
    id
    policyNumber
    state
    versionOnDate(date: $effectiveDate) {
        state
      }
    policyType
    pricingVersion
    paymentMethod
    paymentDueDay
    currentPremiumTotal
    coverAmount
    premiumCreditor
    numFullPayments
    riskPaidUntil
    graceDays
    graceDaysUsedAt 
    doMandate
    debitOrderId
    easyPayNumber
    clientId
    client {
      id
      acceptedTerms
    }
    groupProductId
    premiumCreditorEnabled
    livesCovered {
      id
      clientId
      idNumber
      idType
      firstNames
      surname
      memberType
      coverAmount
      DOB
      age
      gender
      ageAtSignup
      income
      estimatedIncome
      state
      currentPremium
    }
     beneficiaries {
        id
        payoutType
        percentage
        payToId
        payTo {
            id
            clientId
            firstNames
            surname
            idNumber
            idType
            cellNumbers
            email
            relationship
          }
        bankAccount {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
        }
      }
    payments {
      id
      paymentFor
      timestamp
      amount
      paymentType
      reversalReason
    }
    paymentBankAccount {
      id
      bankName
      branchCode
      accountNo
      ownAccount
      accountHolderName
      accountHolderContactNumber
      accountHolderIdNumber
      accountHolderEmail
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
      }
    policyStatusChanges {
      statusAfter
      updatedAt
    }
    cancelReason
    cancelledAt
    accountingIdentifier
    createdAt
    createdBy {
      description
    }
     signupSalesInteraction {
          id
          salesPerson {
            id
            first
            last
            email
          }
        }
  }
`

export const funeralInformation = gql`
  query FuneralInformation($id: String!, $effectiveDate: Float) {
    funeral(id: $id)
      {
      ...FuneralFragment
      }
  }
  ${funeralFragment}
`;



const UPDATE_FUNERAL = gql`
	mutation($input: UpdateFuneralInput!, $id: String!) {
		updateFuneral(id: $id, input: $input) {
      id
		}
	}
`;

const requestDoc = async (id, template) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await fetch(`${API_URL}funeral/${id}/${template}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  return await response.blob()
}

const findDocumentOfType = (documents, type) => {
  if (documents) {
    const doc = documents && documents.filter(d => (d.type && d.type.toUpperCase() === type)).slice(-1)[0]
    return doc ? { url: doc.file.url, id: doc.id, name: doc.name } : null
  }
  else return null
}

let FuneralInfo = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [quote, setQuote] = useState(false)
  const [quoteAmount, setQuoteAmount] = useState()
  const [showBank, setShowBank] = useState(false)

  const [cancelUnCancel, setCancelUnCancel] = useState()
  const [disablePDF, setDisablePDF] = useState(false)

  const updateFuneral = (input) => {
    props.client
      .mutate({
        mutation: UPDATE_FUNERAL,
        variables: {
          id,
          input
        },
        refetchQueries: [{ query: funeralInformation, variables: { id } }]
      })
  }

  const getLast = (value) => {
    return value ? value.length - 1 : 0
  }

  const downloadFuneralDoc = async (template, id, policyNumber) => {
    setDisablePDF(true)
    const blob = await requestDoc(id, template)
    fileDownload(blob, `${policyNumber}_${template}.pdf`)
    setDisablePDF(false)
  }

  const { history, cancel, data, id, effectiveDate } = props;

  const { funeral, loading, error } = data || {};

  if (loading) {
    return <ActivityIndicator />;
  }

  if (error) {
    return <Card>An error occurred ...</Card>;
  }

  if (!funeral) {
    return <Card>Reload ...</Card>;
  }

  const { premiumCreditorEnabled, client, paymentDueDay, paymentMethod, state, versionOnDate, policyNumber, riskPaidUntil, createdAt, createdBy, signupSalesInteraction, paymentBankAccount, easyPayNumber, accountingIdentifier, pricingVersion, livesCovered } = funeral || {};
  const { acceptedTerms } = client || {}
  const allowEdit = state.indexOf('CANCEL') === -1

  const freedom = policyNumber.indexOf('MF') === -1

  const indfun = freedom && pricingVersion && pricingVersion.indexOf('INDFUN') > -1 ? true : false
  const coverageChanges = freedom && !indfun

  if (freedom && livesCovered && livesCovered.length > 0) {
    getQuoteAmount(props.client, funeral, livesCovered.filter(l => l.memberType === 'POLICY_HOLDER' || l.state.indexOf('NO_COVER') === -1), funeral.coverAmount, setQuoteAmount)
  }

  if (quote) {
    return <Quote id={id} mode={freedom ? "add" : 'edit'} funeral={funeral} history={history} client={props.client} coverAmount={funeral.coverAmount} quoteAmount={quoteAmount} cancel={() => setQuote(false)} />
  }
  else return (<>
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={3}>Policy#: {policyNumber}</Grid>
      <Grid item xs={3} >State: {translateState(state)}</Grid>
      {effectiveDate && (differenceInDays(new Date(), new Date(effectiveDate * 1)) !== 0) &&
        <Grid item xs={3}>Version on: {moment(parseInt(effectiveDate)).format('DD-MM-YYYY')}</Grid>}
      {(effectiveDate && versionOnDate && versionOnDate.state && state !== versionOnDate.state) &&
        <Grid item xs={3}>Effective State: {translateState(versionOnDate.state)}</Grid>}
    </Grid>
    <Card>

      <ClientInformation id={funeral.clientId} refetchQuery={funeralInformation} cancel={cancel} history={history} funeralPolicies={[funeral.id]} productId={funeral.id} allowEdit={allowEdit} />
      <br />
      <EditModal Content={EditFuneral} heading="Change Funeral Policy Information" text="Product Information" id={id} refetch={funeralInformation} noButton coverageChanges={coverageChanges} allowEdit={allowEdit} />

      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Policy Type" value={friendlyType(funeral.policyType)} xs={3} />
        <DisplayItem label="Pricing" value={pricingVersion ? pricingVersion : ' '} xs={2} />
        <DisplayItem label="Cover" value={Numeral(funeral.coverAmount).format('$ ##,###0')} />
        <DisplayItem label="Premium" value={Numeral(funeral.currentPremiumTotal).format('$ ##,###0.00')} />
        <DisplayItem label="PayMethod" value={paymentMethod ? paymentMethod : ' '} />
        {easyPayNumber && <DisplayItem type="easypay" clientId={funeral.clientId} label="EasyPay" value={easyPayNumber} />}
        <DisplayItem label="Payment Due" value={paymentDueDay ? paymentDueDay : ' '} />
        <DisplayItem label="Risk Paid To" value={riskPaidUntil ? moment(riskPaidUntil).format('Do MMMM YYYY') : '-'} />
        <DisplayItem label="Payments" value={funeral.numFullPayments ? funeral.numFullPayments : 0} />
        <DisplayItem label="PremiumCreditor" value={funeral.premiumCreditor ? Numeral(funeral.premiumCreditor).format('$ ##,###0.00') : ' '} />
        <DisplayItem label="PC Enabled" value={premiumCreditorEnabled ? "Yes" : "No"} />
        <DisplayItem label="Sold" value={createdAt ? moment(createdAt).format('Do MMMM YYYY') : ''} />
        <DisplayItem label="Sold By" value={funeral.signupSalesInteraction ? funeral.signupSalesInteraction.salesPerson ? `${funeral.signupSalesInteraction.salesPerson.first} ${funeral.signupSalesInteraction.salesPerson.last}` : 'SALES' : funeral.accountingIdentifier ? funeral.accountingIdentifier : funeral.createdBy ? funeral.createdBy.description.includes('API') ? 'MoneyMarket' : funeral.createdBy.description : 'DIGITAL SALE'} />
        {state === "CANCELLED" && funeral.cancelledAt && <DisplayItem label="Cancelled" value={moment(funeral.cancelledAt).format('DD-MM-YYYY DD:MM:ss')} xs={2} />}
        {state === "CANCELLED" && funeral.cancelReason && <DisplayItem label="Reason" value={funeral.cancelReason} xs={2} />}
      </Grid>

      <GroupFuneralInfo id={funeral.groupProductId} refetchQuery={funeralInformation} />

      {open && <Modal open={open}> <Cancel productType={'FUNERAL'} cancelUnCancel={cancelUnCancel} clientId={funeral.clientId} productId={funeral.id} setOpen={setOpen} /> </Modal>}

      <Grid container className={classes.center}>
        {state !== 'CANCELLED' && <>
          {!acceptedTerms &&
            <Grid item xs={2} ><EditModal Content={Terms} noModalButtons text="Accept T&C" heading="Terms & Conditions" dialogText="Has the client accepted?" id={funeral.clientId} allowEdit={allowEdit} />
            </Grid>}
          <Grid item xs={2} >
            <Button color='secondary' onClick={() => {
              setCancelUnCancel("CANCEL");
              setOpen(true)
            }}>Cancel Policy</Button>
          </Grid>
        </>}
        {state === 'CANCELLED' && <Grid item xs={2} >
          <Button color='secondary' onClick={() => {
            setCancelUnCancel("UNCANCEL");
            setOpen(true)
          }}>UnCancel</Button>
        </Grid>}
        <Grid item xs={2} style={{ cursor: 'pointer' }} >
          <Button color="primary" disabled={disablePDF} onClick={() => downloadFuneralDoc("schedule", funeral.id, funeral.policyNumber)} >
            <FaIcons.FaDownload /> &nbsp;
            Schedule
          </Button>
        </Grid>
        <Grid item xs={2} >
          <Button color="primary" disabled={disablePDF} onClick={() => downloadFuneralDoc("policy", funeral.id, funeral.policyNumber)} >
            <FaIcons.FaDownload /> &nbsp;
            Policy
          </Button>
        </Grid>
        {((freedom && ["ACTIVE"].indexOf(funeral.state) === -1) || (false && ["DORMANT", "INFO_MISSING", "INITIAL"].indexOf(funeral.state) > -1)) &&
          <Grid item xs={2}>
            <Button color="primary" onClick={() => setQuote(true)} >
              {freedom ? `Quote ${quoteAmount ? Numeral(quoteAmount).format('$ ##,###0.00') : ' '}` : 'Requote'}
            </Button>
          </Grid>}
      </Grid>

      <LivesCovered funeral={funeral} history={history} effectiveDate={effectiveDate} />
      <br />
      <Beneficiaries funeral={funeral} history={history} />
      <br />

      <BankAccount {...funeral} allowEdit={allowEdit} paymentBankAccount={paymentBankAccount} paymentDay={funeral.paymentDueDay} clientId={funeral.clientId} productId={funeral.id} productType="FUNERAL" amount={funeral.currentPremiumTotal} updateProduct={updateFuneral} />
      <br />

      <Grid container justifyContent='flex-start' spacing={1}>
        <Grid item xs={8}> <PaymentHistory history={funeral.payments} /></Grid>
        <Grid item xs={4}><PolicyStatusHistory history={funeral.policyStatusChanges} /></Grid>
      </Grid>

      <GraceDays {...funeral} />

      <ClientNotes clientId={funeral.clientId} productId={funeral.id} type={"Policy"} />
      <ProductCommunication clientId={funeral.clientId} productId={funeral.id} type={"FUNERAL"} />

      {/* <PaymentStatus id={funeral.payments[0].id} /> */}

      {props.cancel && <Grid container><Grid item xs={4}><br /><Button onClick={props.cancel}>Back</Button></Grid></Grid>}

      <ClientDocuments clientId={funeral.clientId} />
      {/* <AllocationSummary summary={funeral.funeralAllocationSummary} product={"FUNERAL" }/> */}

      <Dialog aria-labelledby="form-dialog-title"
        fullScreen
        onClose={() => setQuote(false)}
        open={false && quote}
      >
        <DialogContent>
          <Quote id={id} mode="add" funeral={funeral} history={history} client={props.client} coverAmount={funeral.coverAmount} quoteAmount={quoteAmount} cancel={() => setQuote(false)} />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={() => setQuote(false)} color="primary" variant="contained" style={{ marginRight: 20 }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  </>
  );
}


FuneralInfo = withApollo(FuneralInfo);
FuneralInfo = graphql(funeralInformation, {
  options: ({ id, effectiveDate }) => {
    let variables = { id }
    if (effectiveDate && effectiveDate !== 'undefined') {
      variables.effectiveDate = effectiveDate
    }
    return ({ variables })
  }
})(FuneralInfo);

export default FuneralInfo;
