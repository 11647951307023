import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { isValidCellphoneNumber, isValidEmail, isValidRsaIdNumber, isInteger } from '../../lib/validations'
import { IdTypes } from "../../variables/IdTypes"
import { Button, renderInput, renderDisplay, BuildLayout } from '../generic';
import { nameCase } from '@foundernest/namecase';
import { Input } from '@chatui/core'

const UPDATE_CLAIM = gql`
	mutation($input: UpdateClaimInput!, $id: String!) {
		updateClaim(id: $id, input: $input) {
      id
		}
	}
`;

const config = {
  name: 'client',
  fields: {
    'firstNames': {
      type: 'text',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'surname': {
      type: 'text',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'idType': {
      type: 'select',
      options: IdTypes,
      label: 'ID Country',
      addOnly: true,
      validate: (value, row) => {
        if (!row.DOB && row.idNumber && !value) {
          return 'ID type is Required'
        }
      }
    },
    'idNumber': {
      label: 'Id Number',
      type: 'text',
      addOnly: true,
      validate: (value, row) => {
        if (value && row.idType && row.idType === 'rsa_id' && isValidRsaIdNumber(value)) {
          return 'ID Number is invalid'
        }
      }
    },
    'cellNumbers[0]': {
      type: 'text',
      label: 'Mobile Number*',
      validate: (value) => {
        if (!value) {
          return 'Cell is required'
        }
        if (isValidCellphoneNumber(value)) {
          return 'enter a valid cell number'
        }
      }
    },
    whatsAppNumber: {
      type: 'text',
      label: 'WhatsApp',
      validate: (value) => {
        if (value && isValidCellphoneNumber(value)) {
          return 'enter a valid whatsApp number'
        }
      }
    },
    'email': {
      type: 'email',
      label: 'Email ',
      validate: (value) => {
        if (value && isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    },
    'income': {
      type: 'text',
      label: 'Income ',
      validate: (value) => {
        if (value && isInteger(value)) {
          return 'Enter an integer'
        }
      }
    },
    'smoker': {
      type: 'switch',
      label: 'Smoker '
    },
    'acceptedTerms': {
      type: 'switch',
      label: 'Accepted T&C '
    },
  },
  query: gql`
    query ClientData($id: String!) {
      client(id: $id) {
        id
        firstNames
        surname
        idType
        idNumber
        cellNumbers
        whatsAppNumber
        email
        acceptedTerms
        income
      }
    }`,
  updateMutation: gql`
    mutation UpdateClient($input: UpdateClientInput!, $id: String!) {
      updateClient(id: $id, input: $input) {
        id
        income
      }
    }`,
  addMutation: gql`
    mutation CreateClient($input: CreateClientInput!) {
      createClient(input: $input) {
        id
        income
      }
    }`
}

const FormLayout = (props) => {

  let { clientType, setId, cancel, mode, formName } = props;

  // console.log('edit claimant mode', mode)

  return (
    <div >
      {/* {clientType === 'CLAIMANT' && mode === 'edit' && <Button color='primary' style={{ float: 'right' }} onClick={() => setId()}>Change Claimant</Button>} */}
      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} mode={mode} />
    </div>
  );
}


let EditClient = (props) => {

  // console.log('Edit client props', props)

  const { cancel, id: clientId, history, refetch, clientType, client, claimId } = props
  const [id, setId] = useState(clientId)

  const updateClaim = (claimantId) => {
    client
      .mutate({
        mutation: UPDATE_CLAIM,
        variables: {
          input: {
            claimantId
          },
          id: claimId
        }
      })
      .then(() => cancel())
  }

  return (
    <div>
      <GraphQLFormProvider
        mode={id ? 'edit' : 'add'}
        id={id}
        {...config}
        afterSubmit={({ data }) => {
          if (data.createClient && claimId) {
            updateClaim(data.createClient.id)
          }
          else {
            cancel()
          }
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} clientType={clientType} setId={setId} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => {
          if (data && data.client && data.client.income) {
            if (data.client.income.toString().indexOf(".") > -1) {
              data.client.income = data.client.income.toFixed(0)
            }
          }
          return data.client
        }}
        mapInputVariables={props => {
          let { firstNames, surname, ...input } = props
          if (firstNames) {
            input.firstNames = nameCase(firstNames)
          }
          if (surname) {
            input.surname = nameCase(surname)
          }
          // console.log('edit client input', input)
          return ({ input })
        }}  // defining the input to use the input needed in the update mutation. 
      />
    </div>
  )
}

EditClient = withApollo(EditClient);
export default EditClient