import React, { Component, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { graphql, withApollo } from 'react-apollo';
import { useConfirm } from "material-ui-confirm";
import { GraphQLFormProvider } from 'react-form-helper'
import { ActivityIndicator, Button, DisplayFile, Grid, Modal, Select, Confirm } from '../generic'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router'
import { getAllGroups } from './GroupsList'
import TextField from '@material-ui/core/TextField'
import { ClientInformation, ClientDocuments, ClientNotes, ClientCommunications } from '../shared'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from 'react-apollo'
import BankAccount from '../bankAccount/BankAccount'
import fileDownload from 'js-file-download'
import PolicyStatusHistory from '../credit-life/PolicyStatusHistory'
import GroupFuneralInfo from '../funeral/GroupFuneralInfo'
import GraphQLTable from '../generic/GraphQLTable'
import { DOCUMENT_TYPES } from '../../variables/documentTypes';
import WhatsAppChat from '../communication/WhatsAppChat';
import ProductCommunication from '../communication/ProductCommunication';
import { CREATE_NOTE } from '../shared/client/ClientNotes';
import PaymentHistory from '../credit-life/PaymentHistory';
import OutstandingRequirements from '../clearance-certificate/OutstandingRequirements';
import { API_URL } from '../../config';
import ContributionsHistory from './ContributionsHistory';
import Cancel from '../shared/Cancel';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 7
  }
}));

export const GET_GROUP = gql`
query ($id: String!) {
  group(id: $id)
  {
     id
     name
     roleHolders {
      clientId
      role
      idDocumentId
      selfieId
      signatureId
      client{
          id
          firstNames
          surname
      }  
    }
    products {
      id
      state
      groupProductNumber
      paymentDueDay
      debitOrderId
      outstandingRequirements
      balance
      stateChanges {
        statusAfter
        updatedAt
      }
      paymentBankAccount {
        id
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderContactNumber
        accountHolderIdNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
      }
      payments {
        id
        paymentFor
        timestamp
        amount
        paymentType
        reversalReason
      }
      groupContributions {
        period
        payments {
          amount
        }
        contributions { 
          amount
        }
        isPaid
        unitDate
      }
    }
  }
}
`

const GET_FUNERALS = (groupProductId) => gql`{funerals(filter: {groupProductId: "${groupProductId}"}){
  id
  policyNumber
  state
  client {
    firstNames
    surname
    idNumber
  }
}
}
`

const UPDATE_GROUP_PRODUCT = gql`
  mutation ($input: UpdateGroupProductInput!, $id: String!) {
    updateGroupProduct(id: $id, input: $input) {
        id
        paymentDueDay
        debitOrderId
        paymentBankAccount {
          id
          bankName
          branchCode
          accountNo
          ownAccount
          accountHolderName
          accountHolderContactNumber
          accountHolderIdNumber
          accountHolderEmail
          idNumberMatch
          nameMatch
          phoneMatch
          accountExists
          avsRawData
      }
    }
  }
`

const SEND_SIGNATURE_LINK = gql`
	mutation($groupId: String!, $clientId: String) {
		groupSignatureLink(groupId: $groupId, clientId: $clientId ) {
      id
		}
	}
`;

const UPDATE_ROLE_HOLDER = gql`
	mutation($id: String!, $input:UpdateGroupInput! ) {
		updateGroup(id: $id, input: $input) {
      id
    }
  }
`;

const UPLOAD_FUNERAL_DOCUMENT = gql`
mutation validateAndProcessBulkUploadFile($fileId: ObjID!, $groupProductNumber: String!) {
  validateAndProcessBulkUploadFile(fileId: $fileId, groupProductNumber: $groupProductNumber) 
}
`

const mapRoleHolders = (roleHolders, clientId, signatureId) => {
  return (roleHolders || []).map((roleHolder) => {
    if ((roleHolder.clientId || "").toString() === (clientId || "").toString()) {
      return {
        clientId: roleHolder.clientId,
        role: roleHolder.role,
        idDocumentId: roleHolder.idDocumentId,
        selfieId: roleHolder.selfieId,
        signatureId
      }
      // these return objects must be the same as the GroupRoleHolderInput
    }
    return {
      clientId: roleHolder.clientId,
      role: roleHolder.role,
      idDocumentId: roleHolder.idDocumentId,
      selfieId: roleHolder.selfieId,
      signatureId: roleHolder.signatureId
    }
  })
}


function FuneralMemberList({ groupProductId, history }) {
  const config = {
    query: GET_FUNERALS(groupProductId),
    dataset: (data) => data.funerals,
    fields: {
      'client.firstNames': { label: 'First Names' },
      'client.surname': { label: 'Surname' },
      'client.idNumber': {label: 'Id Number'},
      policyNumber: { label: 'Policy Number' },
      state: { label: 'State' }
    },
    defaultSorted: [{ id: 'policyNumber' }]
  }
  return <GraphQLTable config={config} onRowPress={(funeral) => history.push(`/funeral/${funeral.id}/edit`)} />
}

let Group = (props) => {
  const { mode, id, history } = props
  const { data, error, loading, client: apolloClient } = useQuery(GET_GROUP, { variables: { id } })
  const confirm = useConfirm();
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackText, setSnackText] = useState()
  const [showSpinner, setShowSpinner] = useState(false)
  const [open, setOpen] = useState(false)
  const [openValidationModal, setOpenValidationModal] = useState(false)
  const [validationResult, setValidationResult] = useState([])
  const [documentType, setDocumentType] = useState()
  const [description, setDescription] = useState()
  const [clientInfo, setClientInfo] = useState(false)
  const [cancelUnCancel, setCancelUnCancel] = useState()
  const [openCancel, setOpenCancel] = useState(false)
  const [verifyDoc] = useMutation(UPLOAD_FUNERAL_DOCUMENT)

  if (loading) {
    return <ActivityIndicator />
  }

  const { group } = data || {}
  const { products } = group || {}
  const signatureRequirement = (products && products[0] && products[0].outstandingRequirements || []).some(requirement => (requirement.toLowerCase()).includes('signature'))

  const updateGroupProduct = (id) => (input) => {
    apolloClient.mutate({
      mutation: UPDATE_GROUP_PRODUCT,
      variables: {
        id,
        input
      }    
    })
  }

  const groupProductMembersReport = async (groupProductId) => {
    setShowSpinner(true);
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`${API_URL}funeralGroupMembersReport/${groupProductId}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
    const data = await response.blob()
    fileDownload(data, `funeralGroupMembersReport.xlsx`)
    setShowSpinner(false);
  }

  const sendSignatureLink = (groupId, clientId) => {
    confirm({
      title: 'Send Signature Link via SMS',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Press 'OK' to go ahead with sending the link, press 'CANCEL' stop the operation.`
    }).then(() => {
      props.client
        .mutate({
          mutation: SEND_SIGNATURE_LINK,
          variables: {
            groupId,
            clientId
          }
        }).then(() => {
          setSnackSeverity('success')
          setSnackOpen(true);
          setSnackText('Signature SMS Reminder sent');
        })
    })
  }

  const removeRoleHolder = (groupId, roleHolders, roleHolderId) => {
    const remainingRoleHolders = (roleHolders || []).filter(roleHolder => (roleHolder.clientId || "").toString() !== (roleHolderId || "").toString())
    const removedRoleHolder = (roleHolders || []).find(roleHolder => (roleHolder.clientId || "").toString() === (roleHolderId || "").toString())
    confirm({
      title: 'Remove Role Holder',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Press 'OK' to go ahead with removing the Role Holder, press 'CANCEL' stop the operation.`
    }).then(() => {
      const associatedWith = [{ type:'Group', id: groupId,  }]
      const input = {text: `Role Holder (${removedRoleHolder.role}): ${removedRoleHolder.client.firstNames} ${removedRoleHolder.client.surname} removed`, associatedWith }
      props.client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
      updateRoleHolder(groupId, remainingRoleHolders)
    })
  }

  const updateRoleHolder = (groupId, roleHolders, clientId, fileId) => {
    props.client
      .mutate({
        mutation: UPDATE_ROLE_HOLDER,
        variables: {
          id: groupId,
          input: { roleHolders: mapRoleHolders(roleHolders, clientId, fileId) }
        }, refetchQueries: [{ query: GET_GROUP, variables: { id } }]
      }).then(() => {
        setSnackSeverity('success')
        setSnackOpen(true);
        setSnackText('Role Holder Updated');
      })
  }
  
  const uploadMemberFile = async(fileId, product) => {
    const productNumber = product && product.groupProductNumber && product.groupProductNumber.toString()
    setOpen(false)
    setShowSpinner(true)
    const groupProductId = product.id
    const result = await verifyDoc({
      variables: 
      {
       fileId, groupProductNumber: productNumber 
      },
      refetchQueries: [{ query: GET_FUNERALS(groupProductId) }],
      awaitRefetchQueries: true
    })
    const validationResult = result.data.validateAndProcessBulkUploadFile
    console.log("RESULT...", validationResult)
    setValidationResult(validationResult)
    setOpenValidationModal(true)
    setShowSpinner(false)
  }

  const groupId = id

  return (
    <div>
      <p />
      <Grid container justifyContent='flex-start' textAlign='center' margin='1px' spacing={2}>
        <Grid item xs={2} alignItems='center'>
          <h1>{data.group.name}</h1>
        </Grid>
        <Grid item xs={4} >
          <div  >
            <Button color="primary" onClick={() => history.push(`/groups/${groupId}/add`)} > Add Role Holder </Button>
          </div>
        </Grid>
        {/* <Grid item xs={4}>
        </Grid> */}
        {products[0].state !== 'CANCELLED' && 
          <Grid item xs={2} >
            <Button 
            color='secondary' 
            onClick={() => { 
              setCancelUnCancel("CANCEL");
              setOpenCancel(true) }}>
                Cancel
            </Button>
          </Grid>
                }
        {products[0].state === 'CANCELLED' && 
          <Grid item xs={2} >
            <Button 
            color='secondary' 
            onClick={() => {
              setCancelUnCancel("UNCANCEL");
              setOpenCancel(true) }}>
                UnCancel</Button>
          </Grid>
        }
      {openCancel && <Modal open={openCancel}><Cancel productType={"GROUP_FUNERAL"} cancelUnCancel={cancelUnCancel} productId={products[0].id} setOpen={setOpenCancel} /></Modal>}
        <Grid item xs={2} alignItems='center'>
          <h1>State: { products[0].state }</h1>  
        </Grid>
      </Grid>
      {(data.group.products || []).map((product) => (
        <>
          <GroupFuneralInfo id={product.id} />
          <p/> &nbsp;
          <OutstandingRequirements outstandingRequirements={product.outstandingRequirements} />
          <p/> &nbsp;
          <Grid container justifyContent='flex-start' spacing={1}>
            <Grid item xs={7}> <PaymentHistory history={product.payments} /></Grid>
            <Grid item xs={5}><PolicyStatusHistory history={product.stateChanges} /></Grid>
          </Grid>
          <p/> &nbsp;
          <ContributionsHistory contributions={product.groupContributions} balance={product.balance} />
          <p/> &nbsp;
          <ClientNotes groupProductId={product.id} productId={product.id} type={'GroupProduct'} />
          <p/> &nbsp;
          <BankAccount groupId={data.group.id} debitOrderId={product.debitOrderId} productId={product.id} productType={"GROUP_PRODUCT"} paymentDay={data.group.products[0].paymentDueDay} paymentBankAccount={product.paymentBankAccount} updateProduct={updateGroupProduct(product.id)} {...product} /> 
          <p/> &nbsp; 
          <ProductCommunication groupProductId={product.id} groupId={data.group.id} type={"GROUP_PRODUCT"} hideWhatsApp />
          <p/> &nbsp;
        </>
      ))}
      <break/>
      {(data.group.roleHolders || []).map((roleHolder) => (<>
        <ClientInformation key={roleHolder.clientId} title={roleHolder.role} id={roleHolder.clientId} history={history} />
        <>
        {signatureRequirement &&
          <Grid container className={classes.documents} spacing={1}>
          <Grid item>
            <p /> &nbsp;
            <Button color='secondary' onClick={() => sendSignatureLink(id, roleHolder.clientId)}> Send Signature Link to {roleHolder.role} </Button>
            <p/>
          </Grid>
          <Grid item>
            <p/>
            <DisplayFile documentType={'SIGNATURE'} noDelete={true} displayIfValid={true} onChange={(fileId) => updateRoleHolder(data.group.id, data.group.roleHolders, roleHolder.clientId, fileId)} description={'SIGNATURE'} />
          </Grid>
        </Grid>}
          <Grid item >
            <p /> &nbsp;
            <Button color='secondary' onClick={() => removeRoleHolder(id, data.group.roleHolders, roleHolder.clientId)}> Remove {roleHolder.role}</Button>
            <p /> &nbsp;
          </Grid>
          <p/>
          <ProductCommunication clientId={roleHolder.clientId} productId={data.group.products.id} type={"GROUP_PRODUCT"} whatsAppOnly={true} />
          <p /> &nbsp; 
          </>
      </>))}
      <br/>
      {(data.group.products || []).map((product) => (
        <>
          <ClientDocuments groupInfo={{groupId:data.group.id, groupProductId: product.id, roleHolders: data.group.roleHolders}} />
          <p/> &nbsp;
          <Grid container justifyContent='flex-start' textAlign='center' margin='1px' spacing={2}>
            <Grid item xs={5} alignItems='center'>
              <h2>Members</h2>
            </Grid>
            <Grid item xs={4}>
              {showSpinner && <ActivityIndicator center={false} />}
            </Grid>
            <Grid item xs={3} >
              <div style={{ paddingTop: 5, left: '48em' }} >
                <Button color="primary" style={{margin: '1em'}} onClick={() => setOpen(true)} > Upload File </Button>
                <Button color="primary" style={{margin: '5px'}} onClick={() => groupProductMembersReport(product.id)} > Members Report </Button>
              </div>
            </Grid>
          </Grid>
          <FuneralMemberList groupProductId={product.id} history={history} />
          <p/> &nbsp;

          <Modal
            title='Select a file to upload'
            noButtons
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='md'
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Select fullWidth name="documentType" label="Document Type" value={documentType} options={DOCUMENT_TYPES} onChange={(value) => {
                  setDocumentType(value.target.value);
                  setDescription(DOCUMENT_TYPES.filter(d => d.value === value.target.value)[0].label);
                  console.log("Description ... ", description)
                  }} />
              </Grid>
              <Grid item xs={6}>
                {<DisplayFile isXls={true} {...props} documentType={documentType} noDelete={true} displayIfValid={true}
                onChange={(fileId)=> uploadMemberFile(fileId, product)} />}
              </Grid>
              <br />
            </Grid>
            <br />
          </Modal>
          <Modal
            open={openValidationModal}
            title={validationResult.length === 0 ? "Validation successful!" : "Validation failed!"}
            fullWidth={false}
          >
            <div style={{maxHeight:"200px", overflow:"scroll", marginBottom:"4px"}}>
            {validationResult.length !== 0  && validationResult.map((r, idx) => {
              return (
              <>
                <h4>Line {idx+1}:</h4>
                <ul>
                {r.map((rr, index) => {
                  return (
                    <li>{rr}</li>
                  )
                })}
                </ul>
              </>
              )
            })}
            </div>
            <Button onClick={()=>setOpenValidationModal(false)}>{validationResult.length === 0 ? "Done" : "Try again"}</Button>
          </Modal>
        </>
      ))}
      <br/>
      <br />
      <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} >
          {snackText}
        </Alert>
      </Snackbar>
    </div>
  )
}

Group = withApollo(Group);
export default Group
