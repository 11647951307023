import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button, Modal, Grid } from '../generic'
import moment from 'moment';
import { useState } from 'react';
import TextField from '@material-ui/core/TextField'

export const getInfoRequired = gql`
{
  groupproducts(filter: {state: "INFO_MISSING"}){
    id
    group {
      id
      name
      consultant{
        id
        first
        last
      }
    }
    groupProductNumber
    oldGroupProductNumber
    outstandingRequirements
    paymentDueDay
    createdAt
  }
}
`

const config = {
  query: getInfoRequired,
  dataset: (data) => data.groupproducts.filter(p => p.groupProductNumber),
  fields: {
    'group.name': { label: 'Group Name' },
    consultantName: {
      label: 'Consultant',
      accessor: (row) => (row.group.consultant ? (`${row.group.consultant.first} ${row.group.consultant.last}`) : null)
      },
    groupProductNumber: { label: 'Group Product Number' },
    oldGroupProductNumber:{ 
      label: 'Old Group Product Number',
      accessor: (row) => (row.oldGroupProductNumber ? row.oldGroupProductNumber : "-")
     },
    outstandingRequirements: {
      label: 'Outstanding Requirements',
      accessor: (row) => (row.outstandingRequirements.join(', '))
    },
    paymentDueDay: {label: "Payment due day"},
    createdAt: {
      label: "Created at",
    accessor: (row) => moment(row.createdAt).format('DD-MM-YYYY')
   }
  },
  defaultSorted: [{ id: 'createdAt' }]
}

const InfoRequiredList = ({history}) => {
    return (
      <Card>
        <h1>
          Groups In Progress
          <Grid>
          <Button style={{ marginRight: '1em', marginTop: 7 }} color="primary" onClick={() => history.push(`/groups`)}>Back to all groups</Button>
          </Grid>
        </h1>

        {console.log('history is ', history)}
      <GraphQLTable config={config} onRowPress={(groupproducts) => history.push(`/groups/${groupproducts.group.id}`)} />
      </Card>
    )
}

export default InfoRequiredList;