import React, { useEffect } from "react";
import WhatsAppChat from './WhatsAppChat'
import { useQuery, Subscription } from 'react-apollo';
import gql from 'graphql-tag';

const GET_MESSAGES = gql`
  query CommunicationThread($filter: CommunicationFilter!, $clientId: ObjID, $number: String){
    communicationThread(filter: $filter, clientId: $clientId, number: $number) 
    {
    id
    client {
      id
      firstNames
      surname
      idNumber
      cellNumbers
    }
    type
    to
    from
    name
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
    attachments {
      fileName
      fileId
      file {
        id
        contentType
      }
    }
  }
  }
`

const NEW_COMMUNICATION = gql`
  subscription {
  communicationCreated {
    id
    client {
      id
      firstNames
      surname
      idNumber
      cellNumbers
    }
    type
    to
    from
    name
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
    attachments {
      fileName
      fileId
      file {
        id
        contentType
      }
    }
  }
}`;

let WhatsAppMessages = ({ clientId, from, client, name }) => {
  const { data, loading, error, subscribeToMore } = useQuery(GET_MESSAGES, { variables: { filter: { type: "WHATSAPP" }, clientId: clientId, number: from } })  

  useEffect(() => {
    if (data) {
      const unsubscribe = subscribeToMore({
        document: NEW_COMMUNICATION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const newMessage = subscriptionData.data.communicationCreated;
          if (newMessage.type !== "WHATSAPP") return prev 

          if ((from === newMessage.from) || (from === newMessage.to)){
            return {
              ...prev,
              communicationThread: [newMessage, ...prev.communicationThread],
            }
          } else return prev;
        },
      });

      return () => unsubscribe();
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>`Error! ${error.message}`</p>;

  return (
    <WhatsAppChat 
      clientId={clientId} 
      clientInfo={client} 
      from={from} 
      clientName={ client ? client.firstNames : name} 
      messages={data.communicationThread.filter(c => (c.to && c.to.includes(from)) || (c.from && c.from.includes(from)))
        .sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
      } 
    />
  )
}

export default WhatsAppMessages;