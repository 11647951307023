import React from 'react'
import Group from './Group'
import NewGroup from './NewGroup'
import GroupsList from './GroupsList'
import { Route, Switch } from 'react-router-dom'
import RoleHolderPrompt from './RoleHolderPrompt'
import RoleHolder from './RoleHolder'
import InfoRequiredList from './InfoRequiredList'

const Groups = () => (

  <div>
      <Route path="/groups" exact render={({ history }) => <GroupsList history={history} mode={'add'} />} />
      <Route path='/groups/info-required/list' exact render={({history}) => <InfoRequiredList history={history}/>}  />
      <Route path="/groups/:id" exact render={({ match, history }) => (
          <Group history={history} mode={match.params.edit ? 'edit' : 'display'} id={match.params.id} />
        )} />
      <Route path="/groups/:groupId/add" exact component={RoleHolderPrompt} />
      <Route path='/groups/:groupId/:role/new/:idNumber/add' exact component={RoleHolder} />
      <Route path='/groups/:groupId/:role/client/:clientId/add' exact component={RoleHolder} />
      
  </div>
)

export default Groups