import React, { useState, useRef, useEffect } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios'
import loadImage from "blueimp-load-image";
import { API_URL } from '../../../config'
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Paper, Box, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import TiffViewer from './TiffViewer'
import { Button, PdfViewer, ImageCropper } from '..'
import { styles } from './styles.js'
import { getAccessToken } from '../../../services/auth'
import { rejected, approved } from '../../../assets/images'

import 'react-image-crop/dist/ReactCrop.css'

const prettyId = (id) => {
  if (id && id.length === 13) {
    return id.substr(0, 6) + ' ' + id.substr(6, 4) + ' ' + id.substr(10, 2) + ' ' + id.substr(12, 1)
  }
  else return id
}

const firstColumnStyles = {
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 64px)', // subtracting the height of the app bar if there is one
  paddingRight: '16px',
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
  button: {
    minWidth:130,
  }
}))(LinearProgress);

const accessToken = localStorage.getItem('accessToken') || getAccessToken()

function Transition(props) {
  return <Slide direction="up" {...props} />
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function UploadFile(props) {
  const { fileId, classes, url, documentType, contentType, name, onChange, onClose, clientInfo, verified, verifiedBy, noButtons, noApproval, onApproval, noLabel, noDelete, autoLoad } = props
  let { isPdf, isTiff, isXls } = props
  const [imgSrc, setImgSrc] = useState()
  const [tiffSrc, setTiffSrc] = useState()
  const [pdfSrc, setPdfSrc] = useState()
  const [pdfFile, setPdfFile] = useState('')
  const [xlsFile, setXlsFile] = useState('')
  const [xlsSrc, setXlsSrc] = useState()
  // const [newDownload, setNewDownLoad] = useState()
  const imgRef = useRef(null)
  const inputRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  const [allowCrop, setAllowCrop] = useState(false)
  const [progress, setProgress] = useState(0)
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [height, setHeight] = useState()

  useEffect(() => {
    if (autoLoad) {
      inputRef.current.click()
    }
  }, [autoLoad])

  useEffect(() => {
    if (url) {
      isPdf = isPdf || (contentType && contentType.toLowerCase().indexOf('pdf') > -1 || name && name.toLowerCase().indexOf('.pdf') > -1)
      isTiff = isTiff || (contentType && contentType.toLowerCase().indexOf('tif') > -1 || name && name.toLowerCase().indexOf('.tif') > -1)
      isXls = (contentType && contentType.toLowerCase().indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1 || name && name.toLowerCase().indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1)
      setImgSrc(!isPdf && !isTiff ? url : null)
      setPdfSrc(isPdf ? url : null)
      setTiffSrc(isTiff ? url : null)
      setXlsSrc(isXls ? url : null)
      // setNewDownLoad()
    }
    else {
      setImgSrc(null)
      setPdfSrc(null)
      setXlsSrc(null)
    }

  }, [contentType, url]);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget
    setHeight(height > 800 ? 800 : height)
  }

  const updateFile = async (src) => {
    let data = new FormData();
    if (pdfFile) {
      console.log("onUploadFile append file", pdfFile)
      data.append('file', pdfFile);
    }
    else if (xlsFile) {
      console.log("onUploadFile append file", xlsFile)
      data.append('file', xlsFile);
    }
    else {
      const base64Response = await fetch(src);
      const blob = await base64Response.blob();
      const MB = 1000000;
      if (blob && blob.size > MB) return new Error('File size is to big');
      data.append('file', blob, 'customer_document.jpg');
    }

    const config = {
      method: "POST",
      crossDomain: true,
      headers: {
        'Authorization': `JWT ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted)
      }
    }
    setUploading(true)

    axios.put(`${API_URL}upload`,
      data,
      config)
      .then(result => {
        console.log("onUploadFile result", result)
        setUploading(false)
        setProgress(0)
        setImgSrc('');
        setPdfSrc('');
        setPdfFile('');
        setXlsFile('')
        setXlsSrc('')
        // setNewDownLoad(new Blob([result]));
        if (onChange) {
          onChange(result.data[0].id);
        }
        onClose && onClose()
      })
      .catch(console.error)
  }

  const onUploadFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const isPdf = e.target.files[0].type.includes('pdf')
      const isXls = e.target.files[0].type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      // console.log("onUploadFile ", isPdf)
      console.log("onUploadFile file", e.target.files[0])
      if (isPdf) {
        setPdfFile(e.target.files[0])
        setPdfSrc(e.target.files[0])
      }
      else if (isXls) {
        setXlsFile(e.target.files[0])
        setXlsSrc(e.target.files[0])
      }
      else {
        loadImage(
          e.target.files[0],
          img => {
            var base64data = img.toDataURL(`image/jpeg`);
            setImgSrc(base64data);
            setCroppedImage(base64data);
          },
          {
            orientation: true,
            canvas: true
          }
        );
      }

      // const reader = new FileReader();
      // reader.addEventListener('load', () =>
      //   this.setState({ src: reader.result })
      // );
      // reader.readAsDataURL(e.target.files[0]);
    }
  };

  const { firstNames, surname, idNumber } = clientInfo || {}
  const title1 = `${documentType ? 'Document: ' + documentType : ' '} ${verified === true ? 'is APPROVED' : verified === false ? 'was REJECTED' : ''}  ${verifiedBy ? `by ${verifiedBy.description}` : ' '}`
  const title2 = clientInfo ? `Client: ${firstNames} ${surname} ${prettyId(idNumber)}` : ' '
  return (
    <div>
      {!fileId && <div className={classes.container}>
        <Button
          className={classes.button}
          variant="outlined"
          component="label"
          size='small'
        >
          <CloudUploadIcon />
          {!noLabel && documentType}
          <input
            type="file"
            ref={inputRef}
            id='fileUpload'
            name='fileUpload'
            accept="image/png, image/jpeg, .pdf, .xlsx"
            onChange={onUploadFile} style={{ display: 'none' }} />
        </Button>
      </div>}
      <Dialog
        fullWidth
        maxWidth='xl'
        open={Boolean(imgSrc || pdfSrc || tiffSrc || xlsSrc )}
      // TransitionComponent={Transition}
      >
        <Grid container className={classes.mainGrid} spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                {!allowCrop && <>
                  <h2>{title1}</h2>
                  <h2>{title2}</h2>
                </>}
              </Grid>
              <Grid item xs={1}>
                {!isXls && !noApproval && !noButtons && !allowCrop && onApproval && <div className={classes.paper}>
                  <Button className={classes.approvalButton} disabled={!url}
                    onClick={() => {
                      if (url) {
                        console.log('Reject button pressed')
                        onApproval(false)
                      }
                      else {
                        console.log('Reject & upload') // why upload?
                        updateFile(croppedImage || imgSrc || setPdfSrc || setXlsSrc)
                      }
                      onClose && onClose()
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    Reject
                  </Button></div>}
              </Grid>
              <Grid item xs={1}>
                {!isXls && !noApproval && !noButtons && !allowCrop && onApproval && <div className={classes.paper}>
                  <Button className={classes.approvalButton} disabled={!url}
                    onClick={() => {
                      if (url) {
                        console.log('Approve button pressed')
                        onApproval(true)
                      }
                      else {
                        console.log('Approve & upload')
                        updateFile(croppedImage || imgSrc || setPdfSrc || setXlsSrc)
                      }
                      onClose && onClose()
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Approve
                  </Button>
                </div>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Paper style={firstColumnStyles}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              // maxHeight="85vh"
              // maxWidth="70vw"
              // minWidth="60vw"
            >
              <div className={verified ? classes.valid : classes.inValid} > {verified === true ? <img src={approved} /> : verified === false ? <img src={rejected} /> : ' '}</div>

              {Boolean(imgSrc) && !allowCrop && <div style={{ paddingBottom: 82 }}>
                <img
                  ref={imgRef}
                  alt="Not an image"
                  src={imgSrc}
                  onLoad={onImageLoad}
                  crossOrigin="anonymous"
                  height={height}
                  width="auto"
                />
              </div>}

              {Boolean(pdfSrc) && !allowCrop && <PdfViewer file={pdfSrc} />}
              {Boolean(xlsSrc) && <p>{xlsSrc.name}</p>}

              {Boolean(tiffSrc) && <>
                <TiffViewer file={tiffSrc} name={name} />
              </>}

              {Boolean(imgSrc) && allowCrop && 
                  <ImageCropper
                    imageToCrop={imgSrc}
                    onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                />}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={1} >
            {((!allowCrop && croppedImage) || noButtons || Boolean(pdfSrc) || Boolean(xlsSrc)) && <div className={classes.paper}>
              <Button
                variant="contained"
                color="primary"
                disabled={uploading || (allowCrop && !Boolean(croppedImage))}
                className={classes.approvalButton}
                onClick={() => {
                  console.log('update ', noButtons)
                  if (noButtons && !croppedImage) {
                    onClose && onClose()
                  }
                  else {
                    updateFile(croppedImage || imgSrc || pdfSrc || xlsSrc)
                    onClose && onClose()
                  }
                }
                }
              >
                {isXls ? 'Validate' : url ? 'Done' : 'Upload'}
              </Button>
            </div>}
            {(allowCrop) && <div className={classes.paper}>
              <Button
                className={classes.approvalButton}
                onClick={() => {
                  setImgSrc(croppedImage)
                  setAllowCrop(false)
                }}
                variant="contained"
                color="primary"
                disabled={!Boolean(imgSrc) || !Boolean(croppedImage) || uploading}
              >
                Save
              </Button>
            </div>}
            <div className={classes.paper}>
              <Button
                className={classes.approvalButton}
                onClick={() => {
                  if (allowCrop) {
                    setAllowCrop(false)
                    setCroppedImage()
                  } else {
                    setImgSrc('');
                    setPdfSrc('');
                    setXlsSrc('')
                    onClose && onClose()
                  }
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </div>
            {!allowCrop && !xlsSrc && !pdfSrc && <div className={classes.paper}>
              <Button
                className={classes.approvalButton}
                onClick={() => setAllowCrop(true)}
                variant="contained"
                disabled={!imgSrc || uploading}
              >
                Crop
              </Button>
            </div>}

            {!allowCrop && (Boolean(imgSrc) || Boolean(pdfSrc)) && <div className={classes.paper}>
              <Button className={classes.approvalButton} >
                <a href={url} style={{ color: '#000000de' }} target="_blank" rel="noopener noreferrer" download>
                  Download
                </a>
              </Button>
            </div>}
            {false && !noDelete && url && <div className={classes.paper}>
              <Button
                className={classes.approvalButton}
                onClick={() => {
                  onChange(null)
                }}
                variant="contained"
                color="secondary"
              >
                Delete
              </Button>
            </div>}

            {progress && progress > 0 ? <div className={classes.paper}>
              {Boolean(imgSrc || pdfSrc) && (
                <Box className="mb25" display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <BorderLinearProgress variant="determinate" value={progress} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                  </Box>
                </Box>)
              }
            </div> : <div />}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}
export default withStyles(styles)(UploadFile)
